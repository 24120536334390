/* tslint:disable */
/* eslint-disable */
/**
 * Snkr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.2
 * Contact: contact@xkickz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface IAddress
 */
export interface IAddress {
    /**
     * Address\'s unique uuid
     * @type {string}
     * @memberof IAddress
     */
    'addressId'?: string;
    /**
     * 
     * @type {ICity}
     * @memberof IAddress
     */
    'city': ICity;
    /**
     * 
     * @type {ICounty}
     * @memberof IAddress
     */
    'county': ICounty;
    /**
     * The country
     * @type {number}
     * @memberof IAddress
     */
    'country': number;
    /**
     * Full address
     * @type {string}
     * @memberof IAddress
     */
    'address': string;
    /**
     * The postal code. Must contains only digits and dash.
     * @type {string}
     * @memberof IAddress
     */
    'postalCode'?: string;
    /**
     * An alias for the address
     * @type {string}
     * @memberof IAddress
     */
    'alias'?: string;
    /**
     * 
     * @type {IAddressTypeEnum}
     * @memberof IAddress
     */
    'type': IAddressTypeEnum;
    /**
     * User\'s phone number can contains digits, spaces, +, .
     * @type {string}
     * @memberof IAddress
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IAddress
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IAddress
     */
    'favorite'?: boolean;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IAddress
     */
    'createdAt'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IAddress
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IAddressTypeEnum = {
    Delivery: 'DELIVERY',
    Sender: 'SENDER'
} as const;

export type IAddressTypeEnum = typeof IAddressTypeEnum[keyof typeof IAddressTypeEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const IBoxConditionEnum = {
    New: 'NEW',
    Damaged: 'DAMAGED',
    Missing: 'MISSING'
} as const;

export type IBoxConditionEnum = typeof IBoxConditionEnum[keyof typeof IBoxConditionEnum];


/**
 * 
 * @export
 * @interface IBrand
 */
export interface IBrand {
    /**
     * unique brand id
     * @type {number}
     * @memberof IBrand
     */
    'brandId'?: number;
    /**
     * The brand\'s name
     * @type {string}
     * @memberof IBrand
     */
    'name'?: string;
    /**
     * path
     * @type {string}
     * @memberof IBrand
     */
    'path'?: string;
    /**
     * size chart link
     * @type {string}
     * @memberof IBrand
     */
    'sizeChartLink'?: string;
}
/**
 * 
 * @export
 * @interface IChatRoom
 */
export interface IChatRoom {
    /**
     * Chat message id
     * @type {string}
     * @memberof IChatRoom
     */
    'chatRoomId'?: string;
    /**
     * Chat message
     * @type {string}
     * @memberof IChatRoom
     */
    'title'?: string;
    /**
     * 
     * @type {Array<IUserSummary>}
     * @memberof IChatRoom
     */
    'users'?: Array<IUserSummary>;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IChatRoom
     */
    'createdAt'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IChatRoom
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ICity
 */
export interface ICity {
    /**
     * unique brand id
     * @type {number}
     * @memberof ICity
     */
    'cityId'?: number;
    /**
     * unique county id
     * @type {number}
     * @memberof ICity
     */
    'countyId'?: number;
    /**
     * The city\'s name
     * @type {string}
     * @memberof ICity
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ICounty
 */
export interface ICounty {
    /**
     * unique county id
     * @type {number}
     * @memberof ICounty
     */
    'countyId': number;
    /**
     * The county\'s name
     * @type {string}
     * @memberof ICounty
     */
    'name': string;
    /**
     * The county\'s code
     * @type {string}
     * @memberof ICounty
     */
    'code': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ICourierEnum = {
    FanCourier: 'FAN_COURIER',
    Dpd: 'DPD',
    Sameday: 'SAMEDAY'
} as const;

export type ICourierEnum = typeof ICourierEnum[keyof typeof ICourierEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const IDeliveryTypeEnum = {
    Courier: 'COURIER',
    PersonalDelivery: 'PERSONAL_DELIVERY'
} as const;

export type IDeliveryTypeEnum = typeof IDeliveryTypeEnum[keyof typeof IDeliveryTypeEnum];


/**
 * 
 * @export
 * @interface IErrorMessage
 */
export interface IErrorMessage {
    /**
     * Name of error message
     * @type {string}
     * @memberof IErrorMessage
     */
    'name'?: string;
    /**
     * Error status code
     * @type {number}
     * @memberof IErrorMessage
     */
    'status'?: number;
    /**
     * Descriptive message for error
     * @type {string}
     * @memberof IErrorMessage
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface IFilterContent
 */
export interface IFilterContent {
    /**
     * 
     * @type {Array<ISizeDTO>}
     * @memberof IFilterContent
     */
    'availableSizes'?: Array<ISizeDTO>;
    /**
     * 
     * @type {Array<ICity>}
     * @memberof IFilterContent
     */
    'availableCities'?: Array<ICity>;
    /**
     * 
     * @type {Array<IBrand>}
     * @memberof IFilterContent
     */
    'brands'?: Array<IBrand>;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IFilterContent
     */
    'minDate'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IFilterContent
     */
    'maxDate'?: string;
    /**
     * price 
     * @type {number}
     * @memberof IFilterContent
     */
    'minPrice'?: number;
    /**
     * price 
     * @type {number}
     * @memberof IFilterContent
     */
    'maxPrice'?: number;
}
/**
 * 
 * @export
 * @interface IForgotPasswordRequest
 */
export interface IForgotPasswordRequest {
    /**
     * route the sent to user in email in order to reset his password
     * @type {string}
     * @memberof IForgotPasswordRequest
     */
    'callbackRoute'?: string;
    /**
     * email for current user
     * @type {string}
     * @memberof IForgotPasswordRequest
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IGenderEnum = {
    Child: 'CHILD',
    Infant: 'INFANT',
    Men: 'MEN',
    Preschool: 'PRESCHOOL',
    Toddler: 'TODDLER',
    Unisex: 'UNISEX',
    Women: 'WOMEN',
    Youth: 'YOUTH'
} as const;

export type IGenderEnum = typeof IGenderEnum[keyof typeof IGenderEnum];


/**
 * 
 * @export
 * @interface IGeneralStatistics
 */
export interface IGeneralStatistics {
    /**
     * number of products for sell
     * @type {number}
     * @memberof IGeneralStatistics
     */
    'forSellProductsNo'?: number;
    /**
     * number of products sold
     * @type {number}
     * @memberof IGeneralStatistics
     */
    'soldProductsNo'?: number;
    /**
     * number of pending products
     * @type {number}
     * @memberof IGeneralStatistics
     */
    'pendingProductsNo'?: number;
    /**
     * number of reported products
     * @type {number}
     * @memberof IGeneralStatistics
     */
    'reportedProductsNo'?: number;
    /**
     * number of reported products
     * @type {number}
     * @memberof IGeneralStatistics
     */
    'rejectedProductsNo'?: number;
    /**
     * number of deleted products
     * @type {number}
     * @memberof IGeneralStatistics
     */
    'deletedProductsNo'?: number;
    /**
     * number of users
     * @type {number}
     * @memberof IGeneralStatistics
     */
    'usersNo'?: number;
    /**
     * number of store
     * @type {number}
     * @memberof IGeneralStatistics
     */
    'storesNo'?: number;
    /**
     * number of order
     * @type {number}
     * @memberof IGeneralStatistics
     */
    'newOrdersNo'?: number;
    /**
     * number of order
     * @type {number}
     * @memberof IGeneralStatistics
     */
    'inProgressOrdersNo'?: number;
    /**
     * number of order
     * @type {number}
     * @memberof IGeneralStatistics
     */
    'canceledOrdersNo'?: number;
    /**
     * number of order
     * @type {number}
     * @memberof IGeneralStatistics
     */
    'doneOrdersNo'?: number;
}
/**
 * 
 * @export
 * @interface IJwtAuthenticationRequest
 */
export interface IJwtAuthenticationRequest {
    /**
     * email for current user
     * @type {string}
     * @memberof IJwtAuthenticationRequest
     */
    'email': string;
    /**
     * password
     * @type {string}
     * @memberof IJwtAuthenticationRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface IJwtAuthenticationResponse
 */
export interface IJwtAuthenticationResponse {
    /**
     * access token
     * @type {string}
     * @memberof IJwtAuthenticationResponse
     */
    'accessToken'?: string;
    /**
     * refresh token
     * @type {string}
     * @memberof IJwtAuthenticationResponse
     */
    'refreshToken'?: string;
}
/**
 * 
 * @export
 * @interface ILegitCheckInfo
 */
export interface ILegitCheckInfo {
    /**
     * Product\'s unique uuid
     * @type {string}
     * @memberof ILegitCheckInfo
     */
    'productId': string;
    /**
     * 
     * @type {IUserSummary}
     * @memberof ILegitCheckInfo
     */
    'user'?: IUserSummary;
    /**
     * 
     * @type {string}
     * @memberof ILegitCheckInfo
     */
    'status': ILegitCheckInfoStatusEnum;
    /**
     * reason why the product is fake/original
     * @type {string}
     * @memberof ILegitCheckInfo
     */
    'reason': string;
    /**
     * link for reference
     * @type {string}
     * @memberof ILegitCheckInfo
     */
    'referenceLink'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof ILegitCheckInfo
     */
    'createdAt'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof ILegitCheckInfo
     */
    'updatedAt'?: string;
}

export const ILegitCheckInfoStatusEnum = {
    Fake: 'FAKE',
    Original: 'ORIGINAL',
    Skiped: 'SKIPED',
    Reject: 'REJECT'
} as const;

export type ILegitCheckInfoStatusEnum = typeof ILegitCheckInfoStatusEnum[keyof typeof ILegitCheckInfoStatusEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const ILoginProviderEnum = {
    Local: 'LOCAL',
    Google: 'GOOGLE',
    Apple: 'APPLE',
    Facebook: 'FACEBOOK'
} as const;

export type ILoginProviderEnum = typeof ILoginProviderEnum[keyof typeof ILoginProviderEnum];


/**
 * 
 * @export
 * @interface INotification
 */
export interface INotification {
    /**
     * Notification\'s unique uuid
     * @type {string}
     * @memberof INotification
     */
    'notificationId': string;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    'body': string;
    /**
     * 
     * @type {boolean}
     * @memberof INotification
     */
    'seen'?: boolean;
    /**
     * 
     * @type {INotificationTypeEnum}
     * @memberof INotification
     */
    'type': INotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof INotification
     */
    'action'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof INotification
     */
    'createdAt'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof INotification
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface INotificationPaged
 */
export interface INotificationPaged {
    /**
     * 
     * @type {Array<INotification>}
     * @memberof INotificationPaged
     */
    'list'?: Array<INotification>;
    /**
     * totalPageNumber
     * @type {number}
     * @memberof INotificationPaged
     */
    'totalPageNumber'?: number;
    /**
     * currentPageNumber
     * @type {number}
     * @memberof INotificationPaged
     */
    'currentPageNumber'?: number;
    /**
     * totalElements
     * @type {number}
     * @memberof INotificationPaged
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const INotificationTypeEnum = {
    NewMessage: 'NEW_MESSAGE',
    NewSneaker: 'NEW_SNEAKER',
    NewProductsOnSneaker: 'NEW_PRODUCTS_ON_SNEAKER',
    OrderUpdates: 'ORDER_UPDATES',
    StoreReviewUpdates: 'STORE_REVIEW_UPDATES',
    AddStoreReview: 'ADD_STORE_REVIEW',
    Subscription: 'SUBSCRIPTION',
    ProductApproved: 'PRODUCT_APPROVED',
    ProductRejected: 'PRODUCT_REJECTED',
    ProductReported: 'PRODUCT_REPORTED',
    AdminProductPending: 'ADMIN_PRODUCT_PENDING',
    AdminProductReported: 'ADMIN_PRODUCT_REPORTED',
    Info: 'INFO',
    Error: 'ERROR'
} as const;

export type INotificationTypeEnum = typeof INotificationTypeEnum[keyof typeof INotificationTypeEnum];


/**
 * 
 * @export
 * @interface IOAuthAuthenticationRequest
 */
export interface IOAuthAuthenticationRequest {
    /**
     * User\'s third party id
     * @type {string}
     * @memberof IOAuthAuthenticationRequest
     */
    'externalId'?: string;
    /**
     * User\'s email. It should be unique
     * @type {string}
     * @memberof IOAuthAuthenticationRequest
     */
    'email': string;
    /**
     * User\'s first name
     * @type {string}
     * @memberof IOAuthAuthenticationRequest
     */
    'firstName': string;
    /**
     * User\'s last name
     * @type {string}
     * @memberof IOAuthAuthenticationRequest
     */
    'lastName': string;
    /**
     * Url to user\'s profile photo
     * @type {string}
     * @memberof IOAuthAuthenticationRequest
     */
    'profilePhotoPath'?: string;
    /**
     * 
     * @type {ILoginProviderEnum}
     * @memberof IOAuthAuthenticationRequest
     */
    'loginProviderEnum'?: ILoginProviderEnum;
}
/**
 * 
 * @export
 * @interface IOrder
 */
export interface IOrder {
    /**
     * Order\'s unique uuid.
     * @type {string}
     * @memberof IOrder
     */
    'orderId'?: string;
    /**
     * User\'s unique uuid for the buyer.
     * @type {string}
     * @memberof IOrder
     */
    'buyerId'?: string;
    /**
     * User\'s unique uuid for the seller.
     * @type {string}
     * @memberof IOrder
     */
    'sellerId': string;
    /**
     * A seller\'s recipient address unique uuid.
     * @type {string}
     * @memberof IOrder
     */
    'recipientAddressId'?: string;
    /**
     * 
     * @type {IAddress}
     * @memberof IOrder
     */
    'recipientAddress'?: IAddress;
    /**
     * 
     * @type {IUserSummary}
     * @memberof IOrder
     */
    'buyer'?: IUserSummary;
    /**
     * 
     * @type {IUserSummary}
     * @memberof IOrder
     */
    'seller'?: IUserSummary;
    /**
     * 
     * @type {IOrderStatusEnum}
     * @memberof IOrder
     */
    'status'?: IOrderStatusEnum;
    /**
     * 
     * @type {IPaymentTypeEnum}
     * @memberof IOrder
     */
    'paymentType': IPaymentTypeEnum;
    /**
     * 
     * @type {IDeliveryTypeEnum}
     * @memberof IOrder
     */
    'deliveryType': IDeliveryTypeEnum;
    /**
     * 
     * @type {ICourierEnum}
     * @memberof IOrder
     */
    'courier'?: ICourierEnum;
    /**
     * The tracking awb number from courier.
     * @type {string}
     * @memberof IOrder
     */
    'awbNumber'?: string;
    /**
     * The offer if the product is negotiable
     * @type {number}
     * @memberof IOrder
     */
    'offer'?: number;
    /**
     * 
     * @type {IOrderProduct}
     * @memberof IOrder
     */
    'product'?: IOrderProduct;
    /**
     * 
     * @type {IOrderProductStock}
     * @memberof IOrder
     */
    'productStock'?: IOrderProductStock;
    /**
     * first message to send to the seller
     * @type {string}
     * @memberof IOrder
     */
    'message'?: string;
    /**
     * unreadMessages
     * @type {number}
     * @memberof IOrder
     */
    'unreadMessages'?: number;
}
/**
 * 
 * @export
 * @interface IOrderFilter
 */
export interface IOrderFilter {
    /**
     * Product stock unique uuid.
     * @type {string}
     * @memberof IOrderFilter
     */
    'productStockId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IOrderFilter
     */
    'userType'?: IOrderFilterUserTypeEnum;
    /**
     * User\'s unique uuid for the buyer. Either buyer or seller is required.
     * @type {string}
     * @memberof IOrderFilter
     */
    'buyerId'?: string;
    /**
     * User\'s unique uuid for the seller. Either buyer or seller is required.
     * @type {string}
     * @memberof IOrderFilter
     */
    'sellerId'?: string;
    /**
     * The order number
     * @type {number}
     * @memberof IOrderFilter
     */
    'orderNumber'?: number;
    /**
     * 
     * @type {IOrderStatusEnum}
     * @memberof IOrderFilter
     */
    'status'?: IOrderStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof IOrderFilter
     */
    'history'?: boolean;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IOrderFilter
     */
    'startDate'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IOrderFilter
     */
    'endDate'?: string;
    /**
     * page number
     * @type {number}
     * @memberof IOrderFilter
     */
    'pageNumber'?: number;
    /**
     * page size
     * @type {number}
     * @memberof IOrderFilter
     */
    'pageSize'?: number;
}

export const IOrderFilterUserTypeEnum = {
    Buyer: 'BUYER',
    Seller: 'SELLER'
} as const;

export type IOrderFilterUserTypeEnum = typeof IOrderFilterUserTypeEnum[keyof typeof IOrderFilterUserTypeEnum];

/**
 * 
 * @export
 * @interface IOrderList
 */
export interface IOrderList {
    /**
     * 
     * @type {Array<IOrder>}
     * @memberof IOrderList
     */
    'list'?: Array<IOrder>;
    /**
     * totalPageNumber
     * @type {number}
     * @memberof IOrderList
     */
    'totalPageNumber'?: number;
    /**
     * currentPageNumber
     * @type {number}
     * @memberof IOrderList
     */
    'currentPageNumber'?: number;
    /**
     * totalElements
     * @type {number}
     * @memberof IOrderList
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface IOrderProduct
 */
export interface IOrderProduct {
    /**
     * Product\'s stock unique uuid.
     * @type {string}
     * @memberof IOrderProduct
     */
    'productStockId': string;
    /**
     * 
     * @type {number}
     * @memberof IOrderProduct
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface IOrderProductStock
 */
export interface IOrderProductStock {
    /**
     * The sneaker\'s photo path.
     * @type {string}
     * @memberof IOrderProductStock
     */
    'sneakerPhotoPath'?: string;
    /**
     * The sneaker\'s title.
     * @type {string}
     * @memberof IOrderProductStock
     */
    'sneakerTitle'?: string;
    /**
     * The product\'s grade
     * @type {number}
     * @memberof IOrderProductStock
     */
    'productGrade'?: number;
    /**
     * The product\'s size
     * @type {number}
     * @memberof IOrderProductStock
     */
    'productSize'?: number;
    /**
     * 
     * @type {IProductConditionEnum}
     * @memberof IOrderProductStock
     */
    'productCondition'?: IProductConditionEnum;
    /**
     * The product\'s price
     * @type {number}
     * @memberof IOrderProductStock
     */
    'productPrice'?: number;
    /**
     * If the product is negotiable or not
     * @type {boolean}
     * @memberof IOrderProductStock
     */
    'isNegotiable'?: boolean;
    /**
     * The best offer.
     * @type {number}
     * @memberof IOrderProductStock
     */
    'bestOffer'?: number;
    /**
     * Number of offers.
     * @type {number}
     * @memberof IOrderProductStock
     */
    'countOffers'?: number;
    /**
     * 
     * @type {IOrderStatusEnum}
     * @memberof IOrderProductStock
     */
    'status'?: IOrderStatusEnum;
    /**
     * The product stock\'s uuid
     * @type {string}
     * @memberof IOrderProductStock
     */
    'productStockId'?: string;
    /**
     * The products uuid
     * @type {string}
     * @memberof IOrderProductStock
     */
    'productId'?: string;
    /**
     * The order uuid
     * @type {string}
     * @memberof IOrderProductStock
     */
    'orderId'?: string;
    /**
     * 
     * @type {IBoxConditionEnum}
     * @memberof IOrderProductStock
     */
    'boxCondition'?: IBoxConditionEnum;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IOrderProductStock
     */
    'createdAt'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IOrderProductStock
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface IOrderProductStockFilter
 */
export interface IOrderProductStockFilter {
    /**
     * 
     * @type {IOrderStatusEnum}
     * @memberof IOrderProductStockFilter
     */
    'status'?: IOrderStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof IOrderProductStockFilter
     */
    'history'?: boolean;
    /**
     * page number
     * @type {number}
     * @memberof IOrderProductStockFilter
     */
    'pageNumber'?: number;
    /**
     * page size
     * @type {number}
     * @memberof IOrderProductStockFilter
     */
    'pageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof IOrderProductStockFilter
     */
    'sortBy'?: IOrderProductStockFilterSortByEnum;
    /**
     * title
     * @type {string}
     * @memberof IOrderProductStockFilter
     */
    'title'?: string;
}

export const IOrderProductStockFilterSortByEnum = {
    RecentlyAdded: 'RECENTLY_ADDED',
    RecentlyUpdated: 'RECENTLY_UPDATED',
    OfferLowToHigh: 'OFFER_LOW_TO_HIGH',
    OfferHighToLow: 'OFFER_HIGH_TO_LOW'
} as const;

export type IOrderProductStockFilterSortByEnum = typeof IOrderProductStockFilterSortByEnum[keyof typeof IOrderProductStockFilterSortByEnum];

/**
 * 
 * @export
 * @interface IOrderProductStockList
 */
export interface IOrderProductStockList {
    /**
     * 
     * @type {Array<IOrderProductStock>}
     * @memberof IOrderProductStockList
     */
    'list'?: Array<IOrderProductStock>;
    /**
     * totalPageNumber
     * @type {number}
     * @memberof IOrderProductStockList
     */
    'totalPageNumber'?: number;
    /**
     * currentPageNumber
     * @type {number}
     * @memberof IOrderProductStockList
     */
    'currentPageNumber'?: number;
    /**
     * totalElements
     * @type {number}
     * @memberof IOrderProductStockList
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IOrderStatusEnum = {
    New: 'NEW',
    InProcess: 'IN_PROCESS',
    InDelivery: 'IN_DELIVERY',
    Done: 'DONE',
    Cancelled: 'CANCELLED',
    Offer: 'OFFER',
    Rejected: 'REJECTED',
    InReturn: 'IN_RETURN'
} as const;

export type IOrderStatusEnum = typeof IOrderStatusEnum[keyof typeof IOrderStatusEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const IOrderTransitionBuyerEnum = {
    NewCancelled: 'NEW->CANCELLED',
    OfferCancelled: 'OFFER->CANCELLED',
    InProcessCancelled: 'IN_PROCESS->CANCELLED',
    InDeliveryDone: 'IN_DELIVERY->DONE',
    InDeliveryInReturn: 'IN_DELIVERY->IN_RETURN'
} as const;

export type IOrderTransitionBuyerEnum = typeof IOrderTransitionBuyerEnum[keyof typeof IOrderTransitionBuyerEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const IOrderTransitionSellerEnum = {
    NewInProcess: 'NEW->IN_PROCESS',
    OfferInProcess: 'OFFER->IN_PROCESS',
    InProcessInDelivery: 'IN_PROCESS->IN_DELIVERY',
    InDeliveryDone: 'IN_DELIVERY->DONE',
    InDeliveryInReturn: 'IN_DELIVERY->IN_RETURN',
    NewRejected: 'NEW->REJECTED',
    OfferRejected: 'OFFER->REJECTED',
    InProcessRejected: 'IN_PROCESS->REJECTED',
    InReturnDone: 'IN_RETURN->DONE'
} as const;

export type IOrderTransitionSellerEnum = typeof IOrderTransitionSellerEnum[keyof typeof IOrderTransitionSellerEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const IPaymentTypeEnum = {
    Cash: 'CASH',
    Card: 'CARD'
} as const;

export type IPaymentTypeEnum = typeof IPaymentTypeEnum[keyof typeof IPaymentTypeEnum];


/**
 * 
 * @export
 * @interface IPhoto
 */
export interface IPhoto {
    /**
     * 
     * @type {IPhotoSideEnum}
     * @memberof IPhoto
     */
    'side'?: IPhotoSideEnum;
    /**
     * path
     * @type {string}
     * @memberof IPhoto
     */
    'path'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IPhotoSideEnum = {
    Front: 'FRONT',
    Back: 'BACK',
    Left: 'LEFT',
    Right: 'RIGHT',
    Up: 'UP',
    Sole: 'SOLE',
    InsideTag: 'INSIDE_TAG',
    InsideSole: 'INSIDE_SOLE',
    Box: 'BOX',
    Aditional: 'ADITIONAL'
} as const;

export type IPhotoSideEnum = typeof IPhotoSideEnum[keyof typeof IPhotoSideEnum];


/**
 * 
 * @export
 * @interface IProduct
 */
export interface IProduct {
    /**
     * unique uuid
     * @type {string}
     * @memberof IProduct
     */
    'productId'?: string;
    /**
     * title of the sneaker
     * @type {string}
     * @memberof IProduct
     */
    'title'?: string;
    /**
     * description
     * @type {string}
     * @memberof IProduct
     */
    'description'?: string;
    /**
     * 
     * @type {IUserSummary}
     * @memberof IProduct
     */
    'productSeller'?: IUserSummary;
    /**
     * unique uuid
     * @type {string}
     * @memberof IProduct
     */
    'sneakerId'?: string;
    /**
     * hasInvoice
     * @type {boolean}
     * @memberof IProduct
     */
    'hasInvoice'?: boolean;
    /**
     * hasInvoice
     * @type {boolean}
     * @memberof IProduct
     */
    'isNegotiable'?: boolean;
    /**
     * 
     * @type {IProductConditionEnum}
     * @memberof IProduct
     */
    'condition'?: IProductConditionEnum;
    /**
     * 
     * @type {IProductStatusEnum}
     * @memberof IProduct
     */
    'status'?: IProductStatusEnum;
    /**
     * 
     * @type {Array<IProductStock>}
     * @memberof IProduct
     */
    'productStocks'?: Array<IProductStock>;
    /**
     * 
     * @type {Array<IPhoto>}
     * @memberof IProduct
     */
    'photos'?: Array<IPhoto>;
    /**
     * 
     * @type {ICity}
     * @memberof IProduct
     */
    'city'?: ICity;
    /**
     * productGrade
     * @type {number}
     * @memberof IProduct
     */
    'productGrade'?: number;
    /**
     * link to a legit check product
     * @type {string}
     * @memberof IProduct
     */
    'legitCheckLink'?: string;
    /**
     * 
     * @type {Array<ILegitCheckInfo>}
     * @memberof IProduct
     */
    'legitCheckInfo'?: Array<ILegitCheckInfo>;
    /**
     * size chart link
     * @type {string}
     * @memberof IProduct
     */
    'sizeChartLink'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IProduct
     */
    'createdAt'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IProduct
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface IProductActivityInfo
 */
export interface IProductActivityInfo {
    /**
     * Product\'s unique uuid
     * @type {string}
     * @memberof IProductActivityInfo
     */
    'productId'?: string;
    /**
     * 
     * @type {IUserSummary}
     * @memberof IProductActivityInfo
     */
    'user'?: IUserSummary;
    /**
     * 
     * @type {IProductStatusEnum}
     * @memberof IProductActivityInfo
     */
    'status'?: IProductStatusEnum;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IProductActivityInfo
     */
    'createdAt'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IProductActivityInfo
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface IProductAdminInfo
 */
export interface IProductAdminInfo {
    /**
     * 
     * @type {IProductStatusEnum}
     * @memberof IProductAdminInfo
     */
    'status'?: IProductStatusEnum;
    /**
     * 
     * @type {Array<ILegitCheckInfo>}
     * @memberof IProductAdminInfo
     */
    'legitCheckInfo'?: Array<ILegitCheckInfo>;
    /**
     * 
     * @type {Array<IProductActivityInfo>}
     * @memberof IProductAdminInfo
     */
    'productActivityInfo'?: Array<IProductActivityInfo>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IProductConditionEnum = {
    New: 'NEW',
    Used: 'USED'
} as const;

export type IProductConditionEnum = typeof IProductConditionEnum[keyof typeof IProductConditionEnum];


/**
 * 
 * @export
 * @interface IProductFilter
 */
export interface IProductFilter {
    /**
     * 
     * @type {Array<number>}
     * @memberof IProductFilter
     */
    'sizeIdList'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof IProductFilter
     */
    'brandIdList'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof IProductFilter
     */
    'cityIdList'?: Array<number>;
    /**
     * color
     * @type {string}
     * @memberof IProductFilter
     */
    'color'?: string;
    /**
     * 
     * @type {IGenderEnum}
     * @memberof IProductFilter
     */
    'gender'?: IGenderEnum;
    /**
     * 
     * @type {IProductConditionEnum}
     * @memberof IProductFilter
     */
    'productCondition'?: IProductConditionEnum;
    /**
     * title
     * @type {string}
     * @memberof IProductFilter
     */
    'title'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IProductFilter
     */
    'startDate'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IProductFilter
     */
    'endDate'?: string;
    /**
     * price 
     * @type {number}
     * @memberof IProductFilter
     */
    'minPrice'?: number;
    /**
     * price 
     * @type {number}
     * @memberof IProductFilter
     */
    'maxPrice'?: number;
    /**
     * page number
     * @type {number}
     * @memberof IProductFilter
     */
    'pageNumber'?: number;
    /**
     * page size
     * @type {number}
     * @memberof IProductFilter
     */
    'pageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof IProductFilter
     */
    'sortBy'?: IProductFilterSortByEnum;
    /**
     * unique uuid
     * @type {string}
     * @memberof IProductFilter
     */
    'userId'?: string;
    /**
     * 
     * @type {IProductStatusEnum}
     * @memberof IProductFilter
     */
    'productStatus'?: IProductStatusEnum;
}

export const IProductFilterSortByEnum = {
    RecentlyAdded: 'RECENTLY_ADDED',
    Popularity: 'POPULARITY',
    PriceLowToHigh: 'PRICE_LOW_TO_HIGH',
    PriceHighToLow: 'PRICE_HIGH_TO_LOW',
    ReleaseDate: 'RELEASE_DATE'
} as const;

export type IProductFilterSortByEnum = typeof IProductFilterSortByEnum[keyof typeof IProductFilterSortByEnum];

/**
 * 
 * @export
 * @interface IProductListPaged
 */
export interface IProductListPaged {
    /**
     * 
     * @type {Array<IProduct>}
     * @memberof IProductListPaged
     */
    'list'?: Array<IProduct>;
    /**
     * totalPageNumber
     * @type {number}
     * @memberof IProductListPaged
     */
    'totalPageNumber'?: number;
    /**
     * currentPageNumber
     * @type {number}
     * @memberof IProductListPaged
     */
    'currentPageNumber'?: number;
    /**
     * totalElements
     * @type {number}
     * @memberof IProductListPaged
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IProductStatusEnum = {
    PendingApproval: 'PENDING_APPROVAL',
    ForSale: 'FOR_SALE',
    Rejected: 'REJECTED',
    Sold: 'SOLD',
    Deleted: 'DELETED',
    Reported: 'REPORTED'
} as const;

export type IProductStatusEnum = typeof IProductStatusEnum[keyof typeof IProductStatusEnum];


/**
 * 
 * @export
 * @interface IProductStock
 */
export interface IProductStock {
    /**
     * unique uuid
     * @type {string}
     * @memberof IProductStock
     */
    'productStockId'?: string;
    /**
     * unique uuid
     * @type {string}
     * @memberof IProductStock
     */
    'productId'?: string;
    /**
     * 
     * @type {ISizeDTO}
     * @memberof IProductStock
     */
    'size'?: ISizeDTO;
    /**
     * stock
     * @type {number}
     * @memberof IProductStock
     */
    'stock'?: number;
    /**
     * price 
     * @type {number}
     * @memberof IProductStock
     */
    'price'?: number;
    /**
     * 
     * @type {IBoxConditionEnum}
     * @memberof IProductStock
     */
    'boxCondition'?: IBoxConditionEnum;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IProductStock
     */
    'createdAt'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IProductStock
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IProductStockStatusEnum = {
    ForSale: 'FOR_SALE',
    Sold: 'SOLD',
    OfferReceived: 'OFFER_RECEIVED',
    Deleted: 'DELETED',
    Ordered: 'ORDERED',
    OfferAccepted: 'OFFER_ACCEPTED',
    PendingApproval: 'PENDING_APPROVAL'
} as const;

export type IProductStockStatusEnum = typeof IProductStockStatusEnum[keyof typeof IProductStockStatusEnum];


/**
 * 
 * @export
 * @interface IResetPasswordRequest
 */
export interface IResetPasswordRequest {
    /**
     * password
     * @type {string}
     * @memberof IResetPasswordRequest
     */
    'password'?: string;
    /**
     * one time token used to validate the password reset
     * @type {string}
     * @memberof IResetPasswordRequest
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface IRetailer
 */
export interface IRetailer {
    /**
     * retailer idRetailersEnum
     * @type {string}
     * @memberof IRetailer
     */
    'id'?: string;
    /**
     * 
     * @type {IRetailersEnum}
     * @memberof IRetailer
     */
    'retailersEnum'?: IRetailersEnum;
    /**
     * url key
     * @type {string}
     * @memberof IRetailer
     */
    'urlKey'?: string;
    /**
     * full url
     * @type {string}
     * @memberof IRetailer
     */
    'url'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof IRetailer
     */
    'photos'?: Array<object>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IRetailersEnum = {
    Xkickx: 'xkickx',
    Stockx: 'stockx',
    Goat: 'goat',
    StadiumGoods: 'stadiumGoods',
    FlightClub: 'flightClub',
    Sneakers123: 'sneakers123'
} as const;

export type IRetailersEnum = typeof IRetailersEnum[keyof typeof IRetailersEnum];


/**
 * 
 * @export
 * @interface ISitemapItem
 */
export interface ISitemapItem {
    /**
     * path
     * @type {string}
     * @memberof ISitemapItem
     */
    'path': string;
    /**
     * name
     * @type {string}
     * @memberof ISitemapItem
     */
    'lastmod': string;
}
/**
 * 
 * @export
 * @interface ISizeDTO
 */
export interface ISizeDTO {
    /**
     * unique size id
     * @type {number}
     * @memberof ISizeDTO
     */
    'sizeId'?: number;
    /**
     * eu
     * @type {string}
     * @memberof ISizeDTO
     */
    'eu'?: string;
    /**
     * us
     * @type {number}
     * @memberof ISizeDTO
     */
    'us'?: number;
    /**
     * uk
     * @type {number}
     * @memberof ISizeDTO
     */
    'uk'?: number;
    /**
     * 
     * @type {IGenderEnum}
     * @memberof ISizeDTO
     */
    'gender'?: IGenderEnum;
}
/**
 * 
 * @export
 * @interface ISizePriceRange
 */
export interface ISizePriceRange {
    /**
     * unique size id
     * @type {number}
     * @memberof ISizePriceRange
     */
    'sizeId'?: number;
    /**
     * price 
     * @type {number}
     * @memberof ISizePriceRange
     */
    'minPrice'?: number;
    /**
     * price 
     * @type {number}
     * @memberof ISizePriceRange
     */
    'maxPrice'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ISizeUoMEnum = {
    Eu: 'EU',
    Us: 'US',
    Uk: 'UK'
} as const;

export type ISizeUoMEnum = typeof ISizeUoMEnum[keyof typeof ISizeUoMEnum];


/**
 * 
 * @export
 * @interface ISneaker
 */
export interface ISneaker {
    /**
     * user unique uuid
     * @type {string}
     * @memberof ISneaker
     */
    'sneakerId'?: string;
    /**
     * 
     * @type {IBrand}
     * @memberof ISneaker
     */
    'brand'?: IBrand;
    /**
     * colorway 
     * @type {string}
     * @memberof ISneaker
     */
    'colorway'?: string;
    /**
     * 
     * @type {IGenderEnum}
     * @memberof ISneaker
     */
    'gender'?: IGenderEnum;
    /**
     * name
     * @type {string}
     * @memberof ISneaker
     */
    'name'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof ISneaker
     */
    'releaseDate'?: string;
    /**
     * retailPrice
     * @type {number}
     * @memberof ISneaker
     */
    'retailPrice'?: number;
    /**
     * retailPrice
     * @type {number}
     * @memberof ISneaker
     */
    'estimatedMarketValue'?: number;
    /**
     * sku
     * @type {string}
     * @memberof ISneaker
     */
    'sku'?: string;
    /**
     * silhouette
     * @type {string}
     * @memberof ISneaker
     */
    'silhouette'?: string;
    /**
     * year
     * @type {string}
     * @memberof ISneaker
     */
    'year'?: string;
    /**
     * description
     * @type {string}
     * @memberof ISneaker
     */
    'description'?: string;
    /**
     * numberOfLikes
     * @type {number}
     * @memberof ISneaker
     */
    'activeProducts'?: number;
    /**
     * numberOfLikes
     * @type {number}
     * @memberof ISneaker
     */
    'numberOfLikes'?: number;
    /**
     * isLikedByUser
     * @type {boolean}
     * @memberof ISneaker
     */
    'isLikedByUser'?: boolean;
    /**
     * 
     * @type {Array<IPhoto>}
     * @memberof ISneaker
     */
    'photos'?: Array<IPhoto>;
    /**
     * photo Path
     * @type {string}
     * @memberof ISneaker
     */
    'photoPath'?: string;
    /**
     * 
     * @type {Array<ISizePriceRange>}
     * @memberof ISneaker
     */
    'sizePriceRange'?: Array<ISizePriceRange>;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00
     * @type {string}
     * @memberof ISneaker
     */
    'createdAt'?: string;
    /**
     * 
     * @type {Array<IRetailer>}
     * @memberof ISneaker
     */
    'retailers'?: Array<IRetailer>;
}
/**
 * 
 * @export
 * @interface ISneakerFilter
 */
export interface ISneakerFilter {
    /**
     * 
     * @type {Array<number>}
     * @memberof ISneakerFilter
     */
    'brandIdList'?: Array<number>;
    /**
     * title
     * @type {string}
     * @memberof ISneakerFilter
     */
    'title'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof ISneakerFilter
     */
    'startDate'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof ISneakerFilter
     */
    'endDate'?: string;
    /**
     * 
     * @type {IGenderEnum}
     * @memberof ISneakerFilter
     */
    'gender'?: IGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof ISneakerFilter
     */
    'sortBy'?: ISneakerFilterSortByEnum;
    /**
     * page number
     * @type {number}
     * @memberof ISneakerFilter
     */
    'pageNumber'?: number;
    /**
     * page size
     * @type {number}
     * @memberof ISneakerFilter
     */
    'pageSize'?: number;
}

export const ISneakerFilterSortByEnum = {
    Popularity: 'POPULARITY',
    ReleaseDate: 'RELEASE_DATE'
} as const;

export type ISneakerFilterSortByEnum = typeof ISneakerFilterSortByEnum[keyof typeof ISneakerFilterSortByEnum];

/**
 * 
 * @export
 * @interface ISneakerListPaged
 */
export interface ISneakerListPaged {
    /**
     * 
     * @type {Array<ISneaker>}
     * @memberof ISneakerListPaged
     */
    'list'?: Array<ISneaker>;
    /**
     * totalPageNumber
     * @type {number}
     * @memberof ISneakerListPaged
     */
    'totalPageNumber'?: number;
    /**
     * currentPageNumber
     * @type {number}
     * @memberof ISneakerListPaged
     */
    'currentPageNumber'?: number;
    /**
     * totalElements
     * @type {number}
     * @memberof ISneakerListPaged
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface IStore
 */
export interface IStore {
    /**
     * Store\'s unique uuid
     * @type {string}
     * @memberof IStore
     */
    'storeId'?: string;
    /**
     * User\'s unique uuid
     * @type {string}
     * @memberof IStore
     */
    'userId'?: string;
    /**
     * Store email. It should be unique
     * @type {string}
     * @memberof IStore
     */
    'email'?: string;
    /**
     * name
     * @type {string}
     * @memberof IStore
     */
    'name': string;
    /**
     * User\'s phone number can contains digits, spaces, +,
     * @type {string}
     * @memberof IStore
     */
    'phoneNumber': string;
    /**
     * about us section
     * @type {string}
     * @memberof IStore
     */
    'aboutUs'?: string;
    /**
     * path
     * @type {string}
     * @memberof IStore
     */
    'logoPath'?: string;
    /**
     * path
     * @type {string}
     * @memberof IStore
     */
    'bannerPath'?: string;
    /**
     * Store\'s facebook account
     * @type {string}
     * @memberof IStore
     */
    'facebookAccount'?: string;
    /**
     * Store\'s instagram account
     * @type {string}
     * @memberof IStore
     */
    'instagramAccount'?: string;
    /**
     *  number of items
     * @type {number}
     * @memberof IStore
     */
    'numberOfProducts'?: number;
    /**
     * 
     * @type {IUserStatusEnum}
     * @memberof IStore
     */
    'storeStatus'?: IUserStatusEnum;
    /**
     * 
     * @type {Array<IStoreComment>}
     * @memberof IStore
     */
    'storeComments'?: Array<IStoreComment>;
    /**
     * 
     * @type {IAddress}
     * @memberof IStore
     */
    'storeAddress'?: IAddress;
    /**
     * store reviews number
     * @type {number}
     * @memberof IStore
     */
    'storeReviews'?: number;
    /**
     * store rating 2/5
     * @type {number}
     * @memberof IStore
     */
    'storeRating'?: number;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IStore
     */
    'createdAt'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IStore
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface IStoreComment
 */
export interface IStoreComment {
    /**
     * Store\'s unique uuid
     * @type {string}
     * @memberof IStoreComment
     */
    'storeCommentId'?: string;
    /**
     * Store\'s unique uuid
     * @type {string}
     * @memberof IStoreComment
     */
    'storeId'?: string;
    /**
     * Order\'s unique uuid
     * @type {string}
     * @memberof IStoreComment
     */
    'orderId'?: string;
    /**
     * Product\'s unique uuid
     * @type {string}
     * @memberof IStoreComment
     */
    'productId'?: string;
    /**
     * Product stock\'s unique uuid
     * @type {string}
     * @memberof IStoreComment
     */
    'productStockId'?: string;
    /**
     * The sneaker\'s photo path.
     * @type {string}
     * @memberof IStoreComment
     */
    'sneakerPhotoPath'?: string;
    /**
     * The sneaker\'s title.
     * @type {string}
     * @memberof IStoreComment
     */
    'sneakerTitle'?: string;
    /**
     * 
     * @type {IUserSummary}
     * @memberof IStoreComment
     */
    'user'?: IUserSummary;
    /**
     * store rating 2/5
     * @type {number}
     * @memberof IStoreComment
     */
    'rating': number;
    /**
     * about us section
     * @type {string}
     * @memberof IStoreComment
     */
    'comment': string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IStoreComment
     */
    'createdAt'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IStoreComment
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface IStoreCommentFilter
 */
export interface IStoreCommentFilter {
    /**
     * 
     * @type {string}
     * @memberof IStoreCommentFilter
     */
    'sortBy'?: IStoreCommentFilterSortByEnum;
    /**
     * page number
     * @type {number}
     * @memberof IStoreCommentFilter
     */
    'pageNumber'?: number;
    /**
     * page size
     * @type {number}
     * @memberof IStoreCommentFilter
     */
    'pageSize'?: number;
}

export const IStoreCommentFilterSortByEnum = {
    CreatedAtAsc: 'CREATED_AT_ASC',
    CreatedAtDesc: 'CREATED_AT_DESC',
    RatingAsc: 'RATING_ASC',
    RatingDesc: 'RATING_DESC'
} as const;

export type IStoreCommentFilterSortByEnum = typeof IStoreCommentFilterSortByEnum[keyof typeof IStoreCommentFilterSortByEnum];

/**
 * 
 * @export
 * @interface IStoreCommentListPaged
 */
export interface IStoreCommentListPaged {
    /**
     * 
     * @type {Array<IStoreComment>}
     * @memberof IStoreCommentListPaged
     */
    'list'?: Array<IStoreComment>;
    /**
     * totalPageNumber
     * @type {number}
     * @memberof IStoreCommentListPaged
     */
    'totalPageNumber'?: number;
    /**
     * currentPageNumber
     * @type {number}
     * @memberof IStoreCommentListPaged
     */
    'currentPageNumber'?: number;
    /**
     * totalElements
     * @type {number}
     * @memberof IStoreCommentListPaged
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface IStoreFilter
 */
export interface IStoreFilter {
    /**
     * 
     * @type {string}
     * @memberof IStoreFilter
     */
    'name'?: string;
    /**
     * 
     * @type {IUserStatusEnum}
     * @memberof IStoreFilter
     */
    'status'?: IUserStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IStoreFilter
     */
    'sortBy'?: IStoreFilterSortByEnum;
    /**
     * page number
     * @type {number}
     * @memberof IStoreFilter
     */
    'pageNumber'?: number;
    /**
     * page size
     * @type {number}
     * @memberof IStoreFilter
     */
    'pageSize'?: number;
}

export const IStoreFilterSortByEnum = {
    CreatedAt: 'CREATED_AT'
} as const;

export type IStoreFilterSortByEnum = typeof IStoreFilterSortByEnum[keyof typeof IStoreFilterSortByEnum];

/**
 * 
 * @export
 * @interface IStoreListPaged
 */
export interface IStoreListPaged {
    /**
     * 
     * @type {Array<IStore>}
     * @memberof IStoreListPaged
     */
    'list'?: Array<IStore>;
    /**
     * totalPageNumber
     * @type {number}
     * @memberof IStoreListPaged
     */
    'totalPageNumber'?: number;
    /**
     * currentPageNumber
     * @type {number}
     * @memberof IStoreListPaged
     */
    'currentPageNumber'?: number;
    /**
     * totalElements
     * @type {number}
     * @memberof IStoreListPaged
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface IStripeSubscription
 */
export interface IStripeSubscription {
    /**
     * The Stripe Subscription\'s uuid
     * @type {string}
     * @memberof IStripeSubscription
     */
    'stripeSubscriptionId'?: string;
    /**
     * name
     * @type {string}
     * @memberof IStripeSubscription
     */
    'name'?: string;
    /**
     * stripePriceExternalId
     * @type {string}
     * @memberof IStripeSubscription
     */
    'stripePriceExternalId'?: string;
    /**
     * If the subscription is free
     * @type {boolean}
     * @memberof IStripeSubscription
     */
    'freeSubscription'?: boolean;
    /**
     * maximum number of items allowed
     * @type {number}
     * @memberof IStripeSubscription
     */
    'includedItems'?: number;
    /**
     * Access to custom logo
     * @type {boolean}
     * @memberof IStripeSubscription
     */
    'customLogo'?: boolean;
    /**
     * Access to custom banner
     * @type {boolean}
     * @memberof IStripeSubscription
     */
    'customBanner'?: boolean;
    /**
     * Access to store metrics
     * @type {boolean}
     * @memberof IStripeSubscription
     */
    'storeMetrics'?: boolean;
    /**
     * The subscription\'s price
     * @type {number}
     * @memberof IStripeSubscription
     */
    'price'?: number;
    /**
     * 
     * @type {ISubscriptionPaymentTypeEnum}
     * @memberof IStripeSubscription
     */
    'paymentType'?: ISubscriptionPaymentTypeEnum;
}
/**
 * 
 * @export
 * @interface ISubscription
 */
export interface ISubscription {
    /**
     * The Stripe Subscription\'s uuid
     * @type {string}
     * @memberof ISubscription
     */
    'subscriptionId'?: string;
    /**
     * maximum number of items allowed
     * @type {number}
     * @memberof ISubscription
     */
    'usedItems'?: number;
    /**
     * stripeId
     * @type {string}
     * @memberof ISubscription
     */
    'stripeId'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof ISubscription
     */
    'startAt'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof ISubscription
     */
    'expiryAt'?: string;
    /**
     * 
     * @type {IStripeSubscription}
     * @memberof ISubscription
     */
    'stripeSubscription'?: IStripeSubscription;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ISubscriptionPaymentTypeEnum = {
    Month: 'MONTH',
    Year: 'YEAR'
} as const;

export type ISubscriptionPaymentTypeEnum = typeof ISubscriptionPaymentTypeEnum[keyof typeof ISubscriptionPaymentTypeEnum];


/**
 * 
 * @export
 * @interface IUpdatePasswordRequest
 */
export interface IUpdatePasswordRequest {
    /**
     * password
     * @type {string}
     * @memberof IUpdatePasswordRequest
     */
    'oldPassword'?: string;
    /**
     * password
     * @type {string}
     * @memberof IUpdatePasswordRequest
     */
    'newPassword'?: string;
}
/**
 * 
 * @export
 * @interface IUploadChatFile200Response
 */
export interface IUploadChatFile200Response {
    /**
     * filename
     * @type {string}
     * @memberof IUploadChatFile200Response
     */
    'fileName'?: string;
}
/**
 * 
 * @export
 * @interface IUser
 */
export interface IUser {
    /**
     * User\'s unique uuid
     * @type {string}
     * @memberof IUser
     */
    'userId'?: string;
    /**
     * User\'s email. It should be unique
     * @type {string}
     * @memberof IUser
     */
    'email': string;
    /**
     * User\'s first name
     * @type {string}
     * @memberof IUser
     */
    'firstName': string;
    /**
     * User\'s last name
     * @type {string}
     * @memberof IUser
     */
    'lastName': string;
    /**
     * User\'s password
     * @type {string}
     * @memberof IUser
     */
    'password'?: string;
    /**
     * User\'s phone number can contains digits, spaces, +, .
     * @type {string}
     * @memberof IUser
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {IUserRoleEnum}
     * @memberof IUser
     */
    'role'?: IUserRoleEnum;
    /**
     * 
     * @type {IUserStatusEnum}
     * @memberof IUser
     */
    'status'?: IUserStatusEnum;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IUser
     */
    'createdAt'?: string;
    /**
     * Date and time expressed according to ISO 8601 (e.g. 2018-06-24T23:10:28+03:00)
     * @type {string}
     * @memberof IUser
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {IUserPreference}
     * @memberof IUser
     */
    'userPreference'?: IUserPreference;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    'profilePhotoPath'?: string;
}
/**
 * 
 * @export
 * @interface IUserBrandSize
 */
export interface IUserBrandSize {
    /**
     * Brand\'s unique uuid
     * @type {number}
     * @memberof IUserBrandSize
     */
    'brandId'?: number;
    /**
     * Size\'s unique uuid
     * @type {number}
     * @memberof IUserBrandSize
     */
    'sizeId'?: number;
}
/**
 * 
 * @export
 * @interface IUserFilter
 */
export interface IUserFilter {
    /**
     * 
     * @type {string}
     * @memberof IUserFilter
     */
    'email'?: string;
    /**
     * 
     * @type {IUserStatusEnum}
     * @memberof IUserFilter
     */
    'status'?: IUserStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IUserFilter
     */
    'sortBy'?: IUserFilterSortByEnum;
    /**
     * page number
     * @type {number}
     * @memberof IUserFilter
     */
    'pageNumber'?: number;
    /**
     * page size
     * @type {number}
     * @memberof IUserFilter
     */
    'pageSize'?: number;
}

export const IUserFilterSortByEnum = {
    CreatedAt: 'CREATED_AT'
} as const;

export type IUserFilterSortByEnum = typeof IUserFilterSortByEnum[keyof typeof IUserFilterSortByEnum];

/**
 * 
 * @export
 * @interface IUserListPaged
 */
export interface IUserListPaged {
    /**
     * 
     * @type {Array<IUser>}
     * @memberof IUserListPaged
     */
    'list'?: Array<IUser>;
    /**
     * totalPageNumber
     * @type {number}
     * @memberof IUserListPaged
     */
    'totalPageNumber'?: number;
    /**
     * currentPageNumber
     * @type {number}
     * @memberof IUserListPaged
     */
    'currentPageNumber'?: number;
    /**
     * totalElements
     * @type {number}
     * @memberof IUserListPaged
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface IUserPreference
 */
export interface IUserPreference {
    /**
     * 
     * @type {IGenderEnum}
     * @memberof IUserPreference
     */
    'gender'?: IGenderEnum;
    /**
     * 
     * @type {Array<IUserBrandSize>}
     * @memberof IUserPreference
     */
    'sizes'?: Array<IUserBrandSize>;
    /**
     * 
     * @type {ISizeUoMEnum}
     * @memberof IUserPreference
     */
    'sizeUoM'?: ISizeUoMEnum;
    /**
     * 
     * @type {boolean}
     * @memberof IUserPreference
     */
    'allowEmailOrderUpdates'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IUserPreference
     */
    'allowEmailNewProducts'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IUserPreference
     */
    'allowEmailNewProductsInstant'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IUserPreference
     */
    'allowEmailNewSneakers'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IUserRoleEnum = {
    Admin: 'ADMIN',
    User: 'USER',
    Buyer: 'BUYER',
    Seller: 'SELLER'
} as const;

export type IUserRoleEnum = typeof IUserRoleEnum[keyof typeof IUserRoleEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const IUserStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Banned: 'BANNED',
    Deleted: 'DELETED'
} as const;

export type IUserStatusEnum = typeof IUserStatusEnum[keyof typeof IUserStatusEnum];


/**
 * 
 * @export
 * @interface IUserSummary
 */
export interface IUserSummary {
    /**
     * User\'s unique uuid
     * @type {string}
     * @memberof IUserSummary
     */
    'userId'?: string;
    /**
     * User\'s unique store uuid
     * @type {string}
     * @memberof IUserSummary
     */
    'storeId'?: string;
    /**
     * name of the seller
     * @type {string}
     * @memberof IUserSummary
     */
    'name'?: string;
    /**
     * photo Path
     * @type {string}
     * @memberof IUserSummary
     */
    'photoPath'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IUserSummary
     */
    'isOnline'?: boolean;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Approve product
         * @summary Approve product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveProduct: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('approveProduct', 'productId', productId)
            const localVarPath = `/admin/product/{productId}/approve`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralStatistics: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all pending products
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingProducts: async (iProductFilter: IProductFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iProductFilter' is not null or undefined
            assertParamExists('getPendingProducts', 'iProductFilter', iProductFilter)
            const localVarPath = `/admin/product/pending/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProductFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get Product info
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductAdminInfo: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductAdminInfo', 'productId', productId)
            const localVarPath = `/admin/product/{productId}/info`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all rejected products
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRejectedProducts: async (iProductFilter: IProductFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iProductFilter' is not null or undefined
            assertParamExists('getRejectedProducts', 'iProductFilter', iProductFilter)
            const localVarPath = `/admin/product/rejected/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProductFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all reported products
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportedProducts: async (iProductFilter: IProductFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iProductFilter' is not null or undefined
            assertParamExists('getReportedProducts', 'iProductFilter', iProductFilter)
            const localVarPath = `/admin/product/reported/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProductFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * Approve product
         * @summary Approve product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveProduct(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveProduct(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralStatistics(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGeneralStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralStatistics(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all pending products
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingProducts(iProductFilter: IProductFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProductListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingProducts(iProductFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get Product info
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductAdminInfo(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProductAdminInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductAdminInfo(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all rejected products
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRejectedProducts(iProductFilter: IProductFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProductListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRejectedProducts(iProductFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all reported products
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportedProducts(iProductFilter: IProductFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProductListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportedProducts(iProductFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * Approve product
         * @summary Approve product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveProduct(productId: string, options?: any): AxiosPromise<void> {
            return localVarFp.approveProduct(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralStatistics(options?: any): AxiosPromise<IGeneralStatistics> {
            return localVarFp.getGeneralStatistics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all pending products
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingProducts(iProductFilter: IProductFilter, options?: any): AxiosPromise<IProductListPaged> {
            return localVarFp.getPendingProducts(iProductFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get Product info
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductAdminInfo(productId: string, options?: any): AxiosPromise<IProductAdminInfo> {
            return localVarFp.getProductAdminInfo(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all rejected products
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRejectedProducts(iProductFilter: IProductFilter, options?: any): AxiosPromise<IProductListPaged> {
            return localVarFp.getRejectedProducts(iProductFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all reported products
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportedProducts(iProductFilter: IProductFilter, options?: any): AxiosPromise<IProductListPaged> {
            return localVarFp.getReportedProducts(iProductFilter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * Approve product
     * @summary Approve product
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public approveProduct(productId: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).approveProduct(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getGeneralStatistics(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getGeneralStatistics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all pending products
     * @param {IProductFilter} iProductFilter The product filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getPendingProducts(iProductFilter: IProductFilter, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getPendingProducts(iProductFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get Product info
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getProductAdminInfo(productId: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getProductAdminInfo(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all rejected products
     * @param {IProductFilter} iProductFilter The product filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getRejectedProducts(iProductFilter: IProductFilter, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getRejectedProducts(iProductFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all reported products
     * @param {IProductFilter} iProductFilter The product filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getReportedProducts(iProductFilter: IProductFilter, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getReportedProducts(iProductFilter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary activates user account\"
         * @param {string} otc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateAccount: async (otc: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'otc' is not null or undefined
            assertParamExists('activateAccount', 'otc', otc)
            const localVarPath = `/authentication/activate-account/{otc}`
                .replace(`{${"otc"}}`, encodeURIComponent(String(otc)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forgotPassword
         * @param {IForgotPasswordRequest} iForgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (iForgotPasswordRequest: IForgotPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iForgotPasswordRequest' is not null or undefined
            assertParamExists('forgotPassword', 'iForgotPasswordRequest', iForgotPasswordRequest)
            const localVarPath = `/authentication/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iForgotPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary authenticate user
         * @param {IJwtAuthenticationRequest} iJwtAuthenticationRequest Authentication request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (iJwtAuthenticationRequest: IJwtAuthenticationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iJwtAuthenticationRequest' is not null or undefined
            assertParamExists('login', 'iJwtAuthenticationRequest', iJwtAuthenticationRequest)
            const localVarPath = `/authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iJwtAuthenticationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary oauth authenticate user
         * @param {IOAuthAuthenticationRequest} iOAuthAuthenticationRequest Authentication request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthLogin: async (iOAuthAuthenticationRequest: IOAuthAuthenticationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iOAuthAuthenticationRequest' is not null or undefined
            assertParamExists('oauthLogin', 'iOAuthAuthenticationRequest', iOAuthAuthenticationRequest)
            const localVarPath = `/authentication/oauth-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iOAuthAuthenticationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * send refresh token to get new access token
         * @summary get new access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/authentication/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication RefreshKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "refreshToken", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary register
         * @param {IUser} iUser The user object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (iUser: IUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iUser' is not null or undefined
            assertParamExists('register', 'iUser', iUser)
            const localVarPath = `/authentication/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reset user password
         * @param {IResetPasswordRequest} iResetPasswordRequest ResetPassword request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (iResetPasswordRequest: IResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iResetPasswordRequest' is not null or undefined
            assertParamExists('resetPassword', 'iResetPasswordRequest', iResetPasswordRequest)
            const localVarPath = `/authentication/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iResetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary activates user account\"
         * @param {string} otc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateAccount(otc: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IJwtAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateAccount(otc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forgotPassword
         * @param {IForgotPasswordRequest} iForgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(iForgotPasswordRequest: IForgotPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(iForgotPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary authenticate user
         * @param {IJwtAuthenticationRequest} iJwtAuthenticationRequest Authentication request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(iJwtAuthenticationRequest: IJwtAuthenticationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IJwtAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(iJwtAuthenticationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary oauth authenticate user
         * @param {IOAuthAuthenticationRequest} iOAuthAuthenticationRequest Authentication request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthLogin(iOAuthAuthenticationRequest: IOAuthAuthenticationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IJwtAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthLogin(iOAuthAuthenticationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * send refresh token to get new access token
         * @summary get new access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refresh(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IJwtAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refresh(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary register
         * @param {IUser} iUser The user object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(iUser: IUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IJwtAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(iUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary reset user password
         * @param {IResetPasswordRequest} iResetPasswordRequest ResetPassword request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(iResetPasswordRequest: IResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IJwtAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(iResetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @summary activates user account\"
         * @param {string} otc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateAccount(otc: string, options?: any): AxiosPromise<IJwtAuthenticationResponse> {
            return localVarFp.activateAccount(otc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forgotPassword
         * @param {IForgotPasswordRequest} iForgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(iForgotPasswordRequest: IForgotPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.forgotPassword(iForgotPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary authenticate user
         * @param {IJwtAuthenticationRequest} iJwtAuthenticationRequest Authentication request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(iJwtAuthenticationRequest: IJwtAuthenticationRequest, options?: any): AxiosPromise<IJwtAuthenticationResponse> {
            return localVarFp.login(iJwtAuthenticationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary oauth authenticate user
         * @param {IOAuthAuthenticationRequest} iOAuthAuthenticationRequest Authentication request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthLogin(iOAuthAuthenticationRequest: IOAuthAuthenticationRequest, options?: any): AxiosPromise<IJwtAuthenticationResponse> {
            return localVarFp.oauthLogin(iOAuthAuthenticationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * send refresh token to get new access token
         * @summary get new access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(options?: any): AxiosPromise<IJwtAuthenticationResponse> {
            return localVarFp.refresh(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary register
         * @param {IUser} iUser The user object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(iUser: IUser, options?: any): AxiosPromise<IJwtAuthenticationResponse> {
            return localVarFp.register(iUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reset user password
         * @param {IResetPasswordRequest} iResetPasswordRequest ResetPassword request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(iResetPasswordRequest: IResetPasswordRequest, options?: any): AxiosPromise<IJwtAuthenticationResponse> {
            return localVarFp.resetPassword(iResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary activates user account\"
     * @param {string} otc 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public activateAccount(otc: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).activateAccount(otc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forgotPassword
     * @param {IForgotPasswordRequest} iForgotPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public forgotPassword(iForgotPasswordRequest: IForgotPasswordRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).forgotPassword(iForgotPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary authenticate user
     * @param {IJwtAuthenticationRequest} iJwtAuthenticationRequest Authentication request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public login(iJwtAuthenticationRequest: IJwtAuthenticationRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).login(iJwtAuthenticationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary oauth authenticate user
     * @param {IOAuthAuthenticationRequest} iOAuthAuthenticationRequest Authentication request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public oauthLogin(iOAuthAuthenticationRequest: IOAuthAuthenticationRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).oauthLogin(iOAuthAuthenticationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * send refresh token to get new access token
     * @summary get new access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public refresh(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).refresh(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary register
     * @param {IUser} iUser The user object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public register(iUser: IUser, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).register(iUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reset user password
     * @param {IResetPasswordRequest} iResetPasswordRequest ResetPassword request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public resetPassword(iResetPasswordRequest: IResetPasswordRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).resetPassword(iResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrandApi - axios parameter creator
 * @export
 */
export const BrandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get all brands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrands: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brand/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandApi - functional programming interface
 * @export
 */
export const BrandApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get all brands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrands(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IBrand>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrands(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrandApi - factory interface
 * @export
 */
export const BrandApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrandApiFp(configuration)
    return {
        /**
         * 
         * @summary get all brands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrands(options?: any): AxiosPromise<Array<IBrand>> {
            return localVarFp.getBrands(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandApi - object-oriented interface
 * @export
 * @class BrandApi
 * @extends {BaseAPI}
 */
export class BrandApi extends BaseAPI {
    /**
     * 
     * @summary get all brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public getBrands(options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).getBrands(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContentManagementApi - axios parameter creator
 * @export
 */
export const ContentManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get FilterContent
         * @summary get Filter Content
         * @param {string} [sneakerId] 
         * @param {string} [storeId] 
         * @param {boolean} [liked] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterContent: async (sneakerId?: string, storeId?: string, liked?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/content-management/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sneakerId !== undefined) {
                localVarQueryParameter['sneakerId'] = sneakerId;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (liked !== undefined) {
                localVarQueryParameter['liked'] = liked;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get sitemap Items
         * @summary get sitemap Items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSitemapItems: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/content-management/sitemap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCrawlerBrands: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/content-management/run-crawler-brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [brand] 
         * @param {string} [gender] 
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCrawlerSneakers: async (brand?: string, gender?: string, sku?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/content-management/run-crawler-sneakers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (brand !== undefined) {
                localVarQueryParameter['brand'] = brand;
            }

            if (gender !== undefined) {
                localVarQueryParameter['gender'] = gender;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentManagementApi - functional programming interface
 * @export
 */
export const ContentManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Get FilterContent
         * @summary get Filter Content
         * @param {string} [sneakerId] 
         * @param {string} [storeId] 
         * @param {boolean} [liked] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilterContent(sneakerId?: string, storeId?: string, liked?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFilterContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilterContent(sneakerId, storeId, liked, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get sitemap Items
         * @summary get sitemap Items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSitemapItems(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ISitemapItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSitemapItems(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runCrawlerBrands(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runCrawlerBrands(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [brand] 
         * @param {string} [gender] 
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runCrawlerSneakers(brand?: string, gender?: string, sku?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ISneaker>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runCrawlerSneakers(brand, gender, sku, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContentManagementApi - factory interface
 * @export
 */
export const ContentManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentManagementApiFp(configuration)
    return {
        /**
         * Get FilterContent
         * @summary get Filter Content
         * @param {string} [sneakerId] 
         * @param {string} [storeId] 
         * @param {boolean} [liked] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterContent(sneakerId?: string, storeId?: string, liked?: boolean, options?: any): AxiosPromise<IFilterContent> {
            return localVarFp.getFilterContent(sneakerId, storeId, liked, options).then((request) => request(axios, basePath));
        },
        /**
         * Get sitemap Items
         * @summary get sitemap Items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSitemapItems(options?: any): AxiosPromise<Array<ISitemapItem>> {
            return localVarFp.getSitemapItems(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCrawlerBrands(options?: any): AxiosPromise<void> {
            return localVarFp.runCrawlerBrands(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [brand] 
         * @param {string} [gender] 
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCrawlerSneakers(brand?: string, gender?: string, sku?: string, options?: any): AxiosPromise<Array<ISneaker>> {
            return localVarFp.runCrawlerSneakers(brand, gender, sku, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentManagementApi - object-oriented interface
 * @export
 * @class ContentManagementApi
 * @extends {BaseAPI}
 */
export class ContentManagementApi extends BaseAPI {
    /**
     * Get FilterContent
     * @summary get Filter Content
     * @param {string} [sneakerId] 
     * @param {string} [storeId] 
     * @param {boolean} [liked] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentManagementApi
     */
    public getFilterContent(sneakerId?: string, storeId?: string, liked?: boolean, options?: AxiosRequestConfig) {
        return ContentManagementApiFp(this.configuration).getFilterContent(sneakerId, storeId, liked, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get sitemap Items
     * @summary get sitemap Items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentManagementApi
     */
    public getSitemapItems(options?: AxiosRequestConfig) {
        return ContentManagementApiFp(this.configuration).getSitemapItems(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentManagementApi
     */
    public runCrawlerBrands(options?: AxiosRequestConfig) {
        return ContentManagementApiFp(this.configuration).runCrawlerBrands(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [brand] 
     * @param {string} [gender] 
     * @param {string} [sku] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentManagementApi
     */
    public runCrawlerSneakers(brand?: string, gender?: string, sku?: string, options?: AxiosRequestConfig) {
        return ContentManagementApiFp(this.configuration).runCrawlerSneakers(brand, gender, sku, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<number>} cityIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitiesByCityIds: async (cityIds: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cityIds' is not null or undefined
            assertParamExists('getCitiesByCityIds', 'cityIds', cityIds)
            const localVarPath = `/location/cities/{cityIds}`
                .replace(`{${"cityIds"}}`, encodeURIComponent(String(cityIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} countyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitiesByCountyId: async (countyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countyId' is not null or undefined
            assertParamExists('getCitiesByCountyId', 'countyId', countyId)
            const localVarPath = `/location/county/{countyId}/cities`
                .replace(`{${"countyId"}}`, encodeURIComponent(String(countyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountiesByCountryId: async (countryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('getCountiesByCountryId', 'countryId', countryId)
            const localVarPath = `/location/country/{countryId}/counties`
                .replace(`{${"countryId"}}`, encodeURIComponent(String(countryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<number>} cityIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitiesByCityIds(cityIds: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitiesByCityIds(cityIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} countyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitiesByCountyId(countyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitiesByCountyId(countyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountiesByCountryId(countryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICounty>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountiesByCountryId(countryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<number>} cityIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitiesByCityIds(cityIds: Array<number>, options?: any): AxiosPromise<Array<ICity>> {
            return localVarFp.getCitiesByCityIds(cityIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} countyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitiesByCountyId(countyId: number, options?: any): AxiosPromise<Array<ICity>> {
            return localVarFp.getCitiesByCountyId(countyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountiesByCountryId(countryId: number, options?: any): AxiosPromise<Array<ICounty>> {
            return localVarFp.getCountiesByCountryId(countryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
    /**
     * 
     * @param {Array<number>} cityIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getCitiesByCityIds(cityIds: Array<number>, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).getCitiesByCityIds(cityIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} countyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getCitiesByCountyId(countyId: number, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).getCitiesByCountyId(countyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} countryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getCountiesByCountryId(countryId: number, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).getCountiesByCountryId(countryId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get user notification
         * @summary get user notification
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotification: async (pageNumber: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getUserNotification', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getUserNotification', 'pageSize', pageSize)
            const localVarPath = `/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * set user notification to seen
         * @summary set user notification to seen
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsSeen: async (notificationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('markAsSeen', 'notificationId', notificationId)
            const localVarPath = `/notification/{notificationId}/seen`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * set user notification with referenceId to seen
         * @summary set user notification with referenceId to seen
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsSeenByReferenceId: async (referenceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('markAsSeenByReferenceId', 'referenceId', referenceId)
            const localVarPath = `/notification/reference/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * get user notification
         * @summary get user notification
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserNotification(pageNumber: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INotificationPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserNotification(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * set user notification to seen
         * @summary set user notification to seen
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAsSeen(notificationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAsSeen(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * set user notification with referenceId to seen
         * @summary set user notification with referenceId to seen
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAsSeenByReferenceId(referenceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAsSeenByReferenceId(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * get user notification
         * @summary get user notification
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotification(pageNumber: number, pageSize: number, options?: any): AxiosPromise<INotificationPaged> {
            return localVarFp.getUserNotification(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * set user notification to seen
         * @summary set user notification to seen
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsSeen(notificationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.markAsSeen(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * set user notification with referenceId to seen
         * @summary set user notification with referenceId to seen
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsSeenByReferenceId(referenceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.markAsSeenByReferenceId(referenceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * get user notification
     * @summary get user notification
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getUserNotification(pageNumber: number, pageSize: number, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).getUserNotification(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * set user notification to seen
     * @summary set user notification to seen
     * @param {string} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public markAsSeen(notificationId: string, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).markAsSeen(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * set user notification with referenceId to seen
     * @summary set user notification with referenceId to seen
     * @param {string} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public markAsSeenByReferenceId(referenceId: string, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).markAsSeenByReferenceId(referenceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates an order
         * @summary Create order
         * @param {IOrder} iOrder The order object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder: async (iOrder: IOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iOrder' is not null or undefined
            assertParamExists('createOrder', 'iOrder', iOrder)
            const localVarPath = `/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iOrder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get orders by specific criteria.
         * @param {IOrderFilter} iOrderFilter The order filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminOrders: async (iOrderFilter: IOrderFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iOrderFilter' is not null or undefined
            assertParamExists('getAdminOrders', 'iOrderFilter', iOrderFilter)
            const localVarPath = `/order/admin-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iOrderFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get orders by specific criteria.
         * @param {IOrderFilter} iOrderFilter The order filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders: async (iOrderFilter: IOrderFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iOrderFilter' is not null or undefined
            assertParamExists('getOrders', 'iOrderFilter', iOrderFilter)
            const localVarPath = `/order/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iOrderFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get orders by specific criteria.
         * @param {IOrderProductStockFilter} iOrderProductStockFilter The filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductStockOrdersBuyer: async (iOrderProductStockFilter: IOrderProductStockFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iOrderProductStockFilter' is not null or undefined
            assertParamExists('getProductStockOrdersBuyer', 'iOrderProductStockFilter', iOrderProductStockFilter)
            const localVarPath = `/order/buyer/product-stock-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iOrderProductStockFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get orders by specific criteria.
         * @param {IOrderProductStockFilter} iOrderProductStockFilter The filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductStockOrdersSeller: async (iOrderProductStockFilter: IOrderProductStockFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iOrderProductStockFilter' is not null or undefined
            assertParamExists('getProductStockOrdersSeller', 'iOrderProductStockFilter', iOrderProductStockFilter)
            const localVarPath = `/order/seller/product-stock-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iOrderProductStockFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an order
         * @summary Updates an order
         * @param {string} orderId 
         * @param {IOrder} iOrder The order object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder: async (orderId: string, iOrder: IOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('updateOrder', 'orderId', orderId)
            // verify required parameter 'iOrder' is not null or undefined
            assertParamExists('updateOrder', 'iOrder', iOrder)
            const localVarPath = `/order/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iOrder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates an order
         * @summary Create order
         * @param {IOrder} iOrder The order object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrder(iOrder: IOrder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrder(iOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get orders by specific criteria.
         * @param {IOrderFilter} iOrderFilter The order filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminOrders(iOrderFilter: IOrderFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrderList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminOrders(iOrderFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get orders by specific criteria.
         * @param {IOrderFilter} iOrderFilter The order filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrders(iOrderFilter: IOrderFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrderList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrders(iOrderFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get orders by specific criteria.
         * @param {IOrderProductStockFilter} iOrderProductStockFilter The filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductStockOrdersBuyer(iOrderProductStockFilter: IOrderProductStockFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrderProductStockList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductStockOrdersBuyer(iOrderProductStockFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get orders by specific criteria.
         * @param {IOrderProductStockFilter} iOrderProductStockFilter The filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductStockOrdersSeller(iOrderProductStockFilter: IOrderProductStockFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrderProductStockList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductStockOrdersSeller(iOrderProductStockFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an order
         * @summary Updates an order
         * @param {string} orderId 
         * @param {IOrder} iOrder The order object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrder(orderId: string, iOrder: IOrder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrder(orderId, iOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * Creates an order
         * @summary Create order
         * @param {IOrder} iOrder The order object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(iOrder: IOrder, options?: any): AxiosPromise<IOrder> {
            return localVarFp.createOrder(iOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get orders by specific criteria.
         * @param {IOrderFilter} iOrderFilter The order filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminOrders(iOrderFilter: IOrderFilter, options?: any): AxiosPromise<IOrderList> {
            return localVarFp.getAdminOrders(iOrderFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get orders by specific criteria.
         * @param {IOrderFilter} iOrderFilter The order filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders(iOrderFilter: IOrderFilter, options?: any): AxiosPromise<IOrderList> {
            return localVarFp.getOrders(iOrderFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get orders by specific criteria.
         * @param {IOrderProductStockFilter} iOrderProductStockFilter The filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductStockOrdersBuyer(iOrderProductStockFilter: IOrderProductStockFilter, options?: any): AxiosPromise<IOrderProductStockList> {
            return localVarFp.getProductStockOrdersBuyer(iOrderProductStockFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get orders by specific criteria.
         * @param {IOrderProductStockFilter} iOrderProductStockFilter The filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductStockOrdersSeller(iOrderProductStockFilter: IOrderProductStockFilter, options?: any): AxiosPromise<IOrderProductStockList> {
            return localVarFp.getProductStockOrdersSeller(iOrderProductStockFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an order
         * @summary Updates an order
         * @param {string} orderId 
         * @param {IOrder} iOrder The order object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder(orderId: string, iOrder: IOrder, options?: any): AxiosPromise<IOrder> {
            return localVarFp.updateOrder(orderId, iOrder, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * Creates an order
     * @summary Create order
     * @param {IOrder} iOrder The order object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public createOrder(iOrder: IOrder, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).createOrder(iOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get orders by specific criteria.
     * @param {IOrderFilter} iOrderFilter The order filter object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getAdminOrders(iOrderFilter: IOrderFilter, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getAdminOrders(iOrderFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get orders by specific criteria.
     * @param {IOrderFilter} iOrderFilter The order filter object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrders(iOrderFilter: IOrderFilter, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrders(iOrderFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get orders by specific criteria.
     * @param {IOrderProductStockFilter} iOrderProductStockFilter The filter object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getProductStockOrdersBuyer(iOrderProductStockFilter: IOrderProductStockFilter, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getProductStockOrdersBuyer(iOrderProductStockFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get orders by specific criteria.
     * @param {IOrderProductStockFilter} iOrderProductStockFilter The filter object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getProductStockOrdersSeller(iOrderProductStockFilter: IOrderProductStockFilter, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getProductStockOrdersSeller(iOrderProductStockFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an order
     * @summary Updates an order
     * @param {string} orderId 
     * @param {IOrder} iOrder The order object to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public updateOrder(orderId: string, iOrder: IOrder, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).updateOrder(orderId, iOrder, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PhotoApi - axios parameter creator
 * @export
 */
export const PhotoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get chat file
         * @summary Get chat file
         * @param {string} bucket 
         * @param {string} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatFile: async (bucket: string, file: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bucket' is not null or undefined
            assertParamExists('getChatFile', 'bucket', bucket)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('getChatFile', 'file', file)
            const localVarPath = `/photo/chat/{bucket}/{file}`
                .replace(`{${"bucket"}}`, encodeURIComponent(String(bucket)))
                .replace(`{${"file"}}`, encodeURIComponent(String(file)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get photo
         * @param {string} bucket 
         * @param {string} name 
         * @param {number} [width] 
         * @param {number} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhoto: async (bucket: string, name: string, width?: number, height?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bucket' is not null or undefined
            assertParamExists('getPhoto', 'bucket', bucket)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getPhoto', 'name', name)
            const localVarPath = `/photo/{bucket}/{name}`
                .replace(`{${"bucket"}}`, encodeURIComponent(String(bucket)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get photo
         * @param {string} brand 
         * @param {string} shoe 
         * @param {string} name 
         * @param {string} hashcode 
         * @param {number} [width] 
         * @param {number} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSneakerPhoto: async (brand: string, shoe: string, name: string, hashcode: string, width?: number, height?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand' is not null or undefined
            assertParamExists('getSneakerPhoto', 'brand', brand)
            // verify required parameter 'shoe' is not null or undefined
            assertParamExists('getSneakerPhoto', 'shoe', shoe)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getSneakerPhoto', 'name', name)
            // verify required parameter 'hashcode' is not null or undefined
            assertParamExists('getSneakerPhoto', 'hashcode', hashcode)
            const localVarPath = `/photo/{brand}/{shoe}/{name}/{hashcode}`
                .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
                .replace(`{${"shoe"}}`, encodeURIComponent(String(shoe)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"hashcode"}}`, encodeURIComponent(String(hashcode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get static photo
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticPhoto: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getStaticPhoto', 'name', name)
            const localVarPath = `/photo/static/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * upload chat file
         * @summary upload chat file
         * @param {string} roomId 
         * @param {any} [file] file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadChatFile: async (roomId: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('uploadChatFile', 'roomId', roomId)
            const localVarPath = `/photo/chat/{roomId}`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PhotoApi - functional programming interface
 * @export
 */
export const PhotoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PhotoApiAxiosParamCreator(configuration)
    return {
        /**
         * Get chat file
         * @summary Get chat file
         * @param {string} bucket 
         * @param {string} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatFile(bucket: string, file: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatFile(bucket, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get photo
         * @param {string} bucket 
         * @param {string} name 
         * @param {number} [width] 
         * @param {number} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhoto(bucket: string, name: string, width?: number, height?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhoto(bucket, name, width, height, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get photo
         * @param {string} brand 
         * @param {string} shoe 
         * @param {string} name 
         * @param {string} hashcode 
         * @param {number} [width] 
         * @param {number} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSneakerPhoto(brand: string, shoe: string, name: string, hashcode: string, width?: number, height?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSneakerPhoto(brand, shoe, name, hashcode, width, height, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get static photo
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaticPhoto(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaticPhoto(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * upload chat file
         * @summary upload chat file
         * @param {string} roomId 
         * @param {any} [file] file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadChatFile(roomId: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUploadChatFile200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadChatFile(roomId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PhotoApi - factory interface
 * @export
 */
export const PhotoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PhotoApiFp(configuration)
    return {
        /**
         * Get chat file
         * @summary Get chat file
         * @param {string} bucket 
         * @param {string} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatFile(bucket: string, file: string, options?: any): AxiosPromise<string> {
            return localVarFp.getChatFile(bucket, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get photo
         * @param {string} bucket 
         * @param {string} name 
         * @param {number} [width] 
         * @param {number} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhoto(bucket: string, name: string, width?: number, height?: number, options?: any): AxiosPromise<string> {
            return localVarFp.getPhoto(bucket, name, width, height, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get photo
         * @param {string} brand 
         * @param {string} shoe 
         * @param {string} name 
         * @param {string} hashcode 
         * @param {number} [width] 
         * @param {number} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSneakerPhoto(brand: string, shoe: string, name: string, hashcode: string, width?: number, height?: number, options?: any): AxiosPromise<string> {
            return localVarFp.getSneakerPhoto(brand, shoe, name, hashcode, width, height, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get static photo
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticPhoto(name: string, options?: any): AxiosPromise<string> {
            return localVarFp.getStaticPhoto(name, options).then((request) => request(axios, basePath));
        },
        /**
         * upload chat file
         * @summary upload chat file
         * @param {string} roomId 
         * @param {any} [file] file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadChatFile(roomId: string, file?: any, options?: any): AxiosPromise<IUploadChatFile200Response> {
            return localVarFp.uploadChatFile(roomId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PhotoApi - object-oriented interface
 * @export
 * @class PhotoApi
 * @extends {BaseAPI}
 */
export class PhotoApi extends BaseAPI {
    /**
     * Get chat file
     * @summary Get chat file
     * @param {string} bucket 
     * @param {string} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoApi
     */
    public getChatFile(bucket: string, file: string, options?: AxiosRequestConfig) {
        return PhotoApiFp(this.configuration).getChatFile(bucket, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get photo
     * @param {string} bucket 
     * @param {string} name 
     * @param {number} [width] 
     * @param {number} [height] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoApi
     */
    public getPhoto(bucket: string, name: string, width?: number, height?: number, options?: AxiosRequestConfig) {
        return PhotoApiFp(this.configuration).getPhoto(bucket, name, width, height, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get photo
     * @param {string} brand 
     * @param {string} shoe 
     * @param {string} name 
     * @param {string} hashcode 
     * @param {number} [width] 
     * @param {number} [height] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoApi
     */
    public getSneakerPhoto(brand: string, shoe: string, name: string, hashcode: string, width?: number, height?: number, options?: AxiosRequestConfig) {
        return PhotoApiFp(this.configuration).getSneakerPhoto(brand, shoe, name, hashcode, width, height, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get static photo
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoApi
     */
    public getStaticPhoto(name: string, options?: AxiosRequestConfig) {
        return PhotoApiFp(this.configuration).getStaticPhoto(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * upload chat file
     * @summary upload chat file
     * @param {string} roomId 
     * @param {any} [file] file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoApi
     */
    public uploadChatFile(roomId: string, file?: any, options?: AxiosRequestConfig) {
        return PhotoApiFp(this.configuration).uploadChatFile(roomId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create any type of Product
         * @summary create product
         * @param {any} [front] file
         * @param {any} [back] file
         * @param {any} [left] file
         * @param {any} [right] file
         * @param {any} [up] file
         * @param {any} [sole] file
         * @param {any} [insideTag] file
         * @param {any} [insideSole] file
         * @param {any} [box] file
         * @param {Array<any>} [aditional] 
         * @param {IProduct} [product] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (front?: any, back?: any, left?: any, right?: any, up?: any, sole?: any, insideTag?: any, insideSole?: any, box?: any, aditional?: Array<any>, product?: IProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (front !== undefined) { 
                localVarFormParams.append('front', front as any);
            }
    
            if (back !== undefined) { 
                localVarFormParams.append('back', back as any);
            }
    
            if (left !== undefined) { 
                localVarFormParams.append('left', left as any);
            }
    
            if (right !== undefined) { 
                localVarFormParams.append('right', right as any);
            }
    
            if (up !== undefined) { 
                localVarFormParams.append('up', up as any);
            }
    
            if (sole !== undefined) { 
                localVarFormParams.append('sole', sole as any);
            }
    
            if (insideTag !== undefined) { 
                localVarFormParams.append('insideTag', insideTag as any);
            }
    
            if (insideSole !== undefined) { 
                localVarFormParams.append('insideSole', insideSole as any);
            }
    
            if (box !== undefined) { 
                localVarFormParams.append('box', box as any);
            }
                if (aditional) {
                aditional.forEach((element) => {
                    localVarFormParams.append('aditional', element as any);
                })
            }

    
            if (product !== undefined) { 
                localVarFormParams.append('product', new Blob([JSON.stringify(product)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a product using an id
         * @summary delete product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteProduct', 'productId', productId)
            const localVarPath = `/product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get one product using an id
         * @summary get one product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductById', 'productId', productId)
            const localVarPath = `/product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get one product for help the community
         * @summary get one product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductForHelpTheCommunity: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/help-the-community`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all products
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts: async (iProductFilter: IProductFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iProductFilter' is not null or undefined
            assertParamExists('getProducts', 'iProductFilter', iProductFilter)
            const localVarPath = `/product/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProductFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get products using an sneaker id
         * @summary get products by snneaker id
         * @param {string} sneakerId 
         * @param {IProductFilter} iProductFilter The sneaker object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsBySneakerId: async (sneakerId: string, iProductFilter: IProductFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sneakerId' is not null or undefined
            assertParamExists('getProductsBySneakerId', 'sneakerId', sneakerId)
            // verify required parameter 'iProductFilter' is not null or undefined
            assertParamExists('getProductsBySneakerId', 'iProductFilter', iProductFilter)
            const localVarPath = `/product/{sneakerId}/list`
                .replace(`{${"sneakerId"}}`, encodeURIComponent(String(sneakerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProductFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all sneakers
         * @param {IProductFilter} iProductFilter The sneaker object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSneakerProducts: async (iProductFilter: IProductFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iProductFilter' is not null or undefined
            assertParamExists('getSneakerProducts', 'iProductFilter', iProductFilter)
            const localVarPath = `/product/sneaker/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProductFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all products for a store
         * @param {string} storeId 
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreProducts: async (storeId: string, iProductFilter: IProductFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getStoreProducts', 'storeId', storeId)
            // verify required parameter 'iProductFilter' is not null or undefined
            assertParamExists('getStoreProducts', 'iProductFilter', iProductFilter)
            const localVarPath = `/product/list/store/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProductFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all products for a user
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProducts: async (iProductFilter: IProductFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iProductFilter' is not null or undefined
            assertParamExists('getUserProducts', 'iProductFilter', iProductFilter)
            const localVarPath = `/product/list/current-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProductFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * legit check Product
         * @summary legit check product
         * @param {string} productId 
         * @param {ILegitCheckInfo} iLegitCheckInfo legit check Product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        legitCheckProduct: async (productId: string, iLegitCheckInfo: ILegitCheckInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('legitCheckProduct', 'productId', productId)
            // verify required parameter 'iLegitCheckInfo' is not null or undefined
            assertParamExists('legitCheckProduct', 'iLegitCheckInfo', iLegitCheckInfo)
            const localVarPath = `/product/{productId}/legit-check`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iLegitCheckInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify a product using an id
         * @summary modify one product
         * @param {string} productId 
         * @param {IProduct} iProduct The product object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (productId: string, iProduct: IProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('updateProduct', 'productId', productId)
            // verify required parameter 'iProduct' is not null or undefined
            assertParamExists('updateProduct', 'iProduct', iProduct)
            const localVarPath = `/product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update product stock
         * @summary Update product stock
         * @param {string} productStockId 
         * @param {IProductStock} iProductStock The product stock object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductStock: async (productStockId: string, iProductStock: IProductStock, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productStockId' is not null or undefined
            assertParamExists('updateProductStock', 'productStockId', productStockId)
            // verify required parameter 'iProductStock' is not null or undefined
            assertParamExists('updateProductStock', 'iProductStock', iProductStock)
            const localVarPath = `/product/{productStockId}/stock`
                .replace(`{${"productStockId"}}`, encodeURIComponent(String(productStockId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProductStock, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * create any type of Product
         * @summary create product
         * @param {any} [front] file
         * @param {any} [back] file
         * @param {any} [left] file
         * @param {any} [right] file
         * @param {any} [up] file
         * @param {any} [sole] file
         * @param {any} [insideTag] file
         * @param {any} [insideSole] file
         * @param {any} [box] file
         * @param {Array<any>} [aditional] 
         * @param {IProduct} [product] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(front?: any, back?: any, left?: any, right?: any, up?: any, sole?: any, insideTag?: any, insideSole?: any, box?: any, aditional?: Array<any>, product?: IProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(front, back, left, right, up, sole, insideTag, insideSole, box, aditional, product, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a product using an id
         * @summary delete product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get one product using an id
         * @summary get one product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductById(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductById(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get one product for help the community
         * @summary get one product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductForHelpTheCommunity(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductForHelpTheCommunity(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all products
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducts(iProductFilter: IProductFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProductListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProducts(iProductFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get products using an sneaker id
         * @summary get products by snneaker id
         * @param {string} sneakerId 
         * @param {IProductFilter} iProductFilter The sneaker object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsBySneakerId(sneakerId: string, iProductFilter: IProductFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProductListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsBySneakerId(sneakerId, iProductFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all sneakers
         * @param {IProductFilter} iProductFilter The sneaker object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSneakerProducts(iProductFilter: IProductFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISneakerListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSneakerProducts(iProductFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all products for a store
         * @param {string} storeId 
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreProducts(storeId: string, iProductFilter: IProductFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProductListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreProducts(storeId, iProductFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all products for a user
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProducts(iProductFilter: IProductFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProductListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProducts(iProductFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * legit check Product
         * @summary legit check product
         * @param {string} productId 
         * @param {ILegitCheckInfo} iLegitCheckInfo legit check Product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async legitCheckProduct(productId: string, iLegitCheckInfo: ILegitCheckInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.legitCheckProduct(productId, iLegitCheckInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify a product using an id
         * @summary modify one product
         * @param {string} productId 
         * @param {IProduct} iProduct The product object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(productId: string, iProduct: IProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(productId, iProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update product stock
         * @summary Update product stock
         * @param {string} productStockId 
         * @param {IProductStock} iProductStock The product stock object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductStock(productStockId: string, iProductStock: IProductStock, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProductStock(productStockId, iProductStock, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * create any type of Product
         * @summary create product
         * @param {any} [front] file
         * @param {any} [back] file
         * @param {any} [left] file
         * @param {any} [right] file
         * @param {any} [up] file
         * @param {any} [sole] file
         * @param {any} [insideTag] file
         * @param {any} [insideSole] file
         * @param {any} [box] file
         * @param {Array<any>} [aditional] 
         * @param {IProduct} [product] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(front?: any, back?: any, left?: any, right?: any, up?: any, sole?: any, insideTag?: any, insideSole?: any, box?: any, aditional?: Array<any>, product?: IProduct, options?: any): AxiosPromise<IProduct> {
            return localVarFp.createProduct(front, back, left, right, up, sole, insideTag, insideSole, box, aditional, product, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a product using an id
         * @summary delete product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(productId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProduct(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get one product using an id
         * @summary get one product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(productId: string, options?: any): AxiosPromise<IProduct> {
            return localVarFp.getProductById(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get one product for help the community
         * @summary get one product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductForHelpTheCommunity(options?: any): AxiosPromise<IProduct> {
            return localVarFp.getProductForHelpTheCommunity(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all products
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(iProductFilter: IProductFilter, options?: any): AxiosPromise<IProductListPaged> {
            return localVarFp.getProducts(iProductFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Get products using an sneaker id
         * @summary get products by snneaker id
         * @param {string} sneakerId 
         * @param {IProductFilter} iProductFilter The sneaker object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsBySneakerId(sneakerId: string, iProductFilter: IProductFilter, options?: any): AxiosPromise<IProductListPaged> {
            return localVarFp.getProductsBySneakerId(sneakerId, iProductFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all sneakers
         * @param {IProductFilter} iProductFilter The sneaker object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSneakerProducts(iProductFilter: IProductFilter, options?: any): AxiosPromise<ISneakerListPaged> {
            return localVarFp.getSneakerProducts(iProductFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all products for a store
         * @param {string} storeId 
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreProducts(storeId: string, iProductFilter: IProductFilter, options?: any): AxiosPromise<IProductListPaged> {
            return localVarFp.getStoreProducts(storeId, iProductFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all products for a user
         * @param {IProductFilter} iProductFilter The product filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProducts(iProductFilter: IProductFilter, options?: any): AxiosPromise<IProductListPaged> {
            return localVarFp.getUserProducts(iProductFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * legit check Product
         * @summary legit check product
         * @param {string} productId 
         * @param {ILegitCheckInfo} iLegitCheckInfo legit check Product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        legitCheckProduct(productId: string, iLegitCheckInfo: ILegitCheckInfo, options?: any): AxiosPromise<void> {
            return localVarFp.legitCheckProduct(productId, iLegitCheckInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Modify a product using an id
         * @summary modify one product
         * @param {string} productId 
         * @param {IProduct} iProduct The product object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(productId: string, iProduct: IProduct, options?: any): AxiosPromise<IProduct> {
            return localVarFp.updateProduct(productId, iProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * Update product stock
         * @summary Update product stock
         * @param {string} productStockId 
         * @param {IProductStock} iProductStock The product stock object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductStock(productStockId: string, iProductStock: IProductStock, options?: any): AxiosPromise<void> {
            return localVarFp.updateProductStock(productStockId, iProductStock, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * create any type of Product
     * @summary create product
     * @param {any} [front] file
     * @param {any} [back] file
     * @param {any} [left] file
     * @param {any} [right] file
     * @param {any} [up] file
     * @param {any} [sole] file
     * @param {any} [insideTag] file
     * @param {any} [insideSole] file
     * @param {any} [box] file
     * @param {Array<any>} [aditional] 
     * @param {IProduct} [product] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public createProduct(front?: any, back?: any, left?: any, right?: any, up?: any, sole?: any, insideTag?: any, insideSole?: any, box?: any, aditional?: Array<any>, product?: IProduct, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).createProduct(front, back, left, right, up, sole, insideTag, insideSole, box, aditional, product, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a product using an id
     * @summary delete product
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public deleteProduct(productId: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).deleteProduct(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get one product using an id
     * @summary get one product
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductById(productId: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProductById(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get one product for help the community
     * @summary get one product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductForHelpTheCommunity(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProductForHelpTheCommunity(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all products
     * @param {IProductFilter} iProductFilter The product filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProducts(iProductFilter: IProductFilter, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProducts(iProductFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get products using an sneaker id
     * @summary get products by snneaker id
     * @param {string} sneakerId 
     * @param {IProductFilter} iProductFilter The sneaker object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductsBySneakerId(sneakerId: string, iProductFilter: IProductFilter, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProductsBySneakerId(sneakerId, iProductFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all sneakers
     * @param {IProductFilter} iProductFilter The sneaker object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getSneakerProducts(iProductFilter: IProductFilter, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getSneakerProducts(iProductFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all products for a store
     * @param {string} storeId 
     * @param {IProductFilter} iProductFilter The product filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getStoreProducts(storeId: string, iProductFilter: IProductFilter, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getStoreProducts(storeId, iProductFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all products for a user
     * @param {IProductFilter} iProductFilter The product filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getUserProducts(iProductFilter: IProductFilter, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getUserProducts(iProductFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * legit check Product
     * @summary legit check product
     * @param {string} productId 
     * @param {ILegitCheckInfo} iLegitCheckInfo legit check Product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public legitCheckProduct(productId: string, iLegitCheckInfo: ILegitCheckInfo, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).legitCheckProduct(productId, iLegitCheckInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify a product using an id
     * @summary modify one product
     * @param {string} productId 
     * @param {IProduct} iProduct The product object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public updateProduct(productId: string, iProduct: IProduct, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).updateProduct(productId, iProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update product stock
     * @summary Update product stock
     * @param {string} productStockId 
     * @param {IProductStock} iProductStock The product stock object to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public updateProductStock(productStockId: string, iProductStock: IProductStock, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).updateProductStock(productStockId, iProductStock, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SneakerApi - axios parameter creator
 * @export
 */
export const SneakerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create any type of Sneaker
         * @summary create sneaker
         * @param {ISneaker} iSneaker The sneaker object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSneaker: async (iSneaker: ISneaker, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iSneaker' is not null or undefined
            assertParamExists('createSneaker', 'iSneaker', iSneaker)
            const localVarPath = `/sneaker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iSneaker, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a sneaker using an id
         * @summary delete sneaker
         * @param {string} sneakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSneaker: async (sneakerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sneakerId' is not null or undefined
            assertParamExists('deleteSneaker', 'sneakerId', sneakerId)
            const localVarPath = `/sneaker/{sneakerId}`
                .replace(`{${"sneakerId"}}`, encodeURIComponent(String(sneakerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get one sneaker using an id
         * @summary get one sneaker
         * @param {string} sneakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSneakerById: async (sneakerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sneakerId' is not null or undefined
            assertParamExists('getSneakerById', 'sneakerId', sneakerId)
            const localVarPath = `/sneaker/{sneakerId}`
                .replace(`{${"sneakerId"}}`, encodeURIComponent(String(sneakerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get sneaker of the day
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSneakerOfTheDay: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sneaker/sneaker-of-the-day`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all sneaker sizes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSneakerSizes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sneaker/sizes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all sneakers
         * @param {ISneakerFilter} iSneakerFilter The sneaker object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSneakers: async (iSneakerFilter: ISneakerFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iSneakerFilter' is not null or undefined
            assertParamExists('getSneakers', 'iSneakerFilter', iSneakerFilter)
            const localVarPath = `/sneaker/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iSneakerFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all liked sneakers by user
         * @param {IProductFilter} iProductFilter The sneaker filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLikedSneakers: async (iProductFilter: IProductFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iProductFilter' is not null or undefined
            assertParamExists('getUserLikedSneakers', 'iProductFilter', iProductFilter)
            const localVarPath = `/sneaker/liked`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProductFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * like/dislike one sneaker
         * @summary like/dislike one sneaker
         * @param {string} sneakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeSneakerById: async (sneakerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sneakerId' is not null or undefined
            assertParamExists('likeSneakerById', 'sneakerId', sneakerId)
            const localVarPath = `/sneaker/{sneakerId}/like`
                .replace(`{${"sneakerId"}}`, encodeURIComponent(String(sneakerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify a sneaker using an id
         * @summary modify one sneaker
         * @param {string} sneakerId 
         * @param {ISneaker} iSneaker The sneaker object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSneaker: async (sneakerId: string, iSneaker: ISneaker, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sneakerId' is not null or undefined
            assertParamExists('updateSneaker', 'sneakerId', sneakerId)
            // verify required parameter 'iSneaker' is not null or undefined
            assertParamExists('updateSneaker', 'iSneaker', iSneaker)
            const localVarPath = `/sneaker/{sneakerId}`
                .replace(`{${"sneakerId"}}`, encodeURIComponent(String(sneakerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iSneaker, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SneakerApi - functional programming interface
 * @export
 */
export const SneakerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SneakerApiAxiosParamCreator(configuration)
    return {
        /**
         * create any type of Sneaker
         * @summary create sneaker
         * @param {ISneaker} iSneaker The sneaker object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSneaker(iSneaker: ISneaker, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISneaker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSneaker(iSneaker, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a sneaker using an id
         * @summary delete sneaker
         * @param {string} sneakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSneaker(sneakerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSneaker(sneakerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get one sneaker using an id
         * @summary get one sneaker
         * @param {string} sneakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSneakerById(sneakerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISneaker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSneakerById(sneakerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get sneaker of the day
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSneakerOfTheDay(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISneaker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSneakerOfTheDay(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all sneaker sizes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSneakerSizes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ISizeDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSneakerSizes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all sneakers
         * @param {ISneakerFilter} iSneakerFilter The sneaker object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSneakers(iSneakerFilter: ISneakerFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ISneaker>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSneakers(iSneakerFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all liked sneakers by user
         * @param {IProductFilter} iProductFilter The sneaker filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserLikedSneakers(iProductFilter: IProductFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISneakerListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserLikedSneakers(iProductFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * like/dislike one sneaker
         * @summary like/dislike one sneaker
         * @param {string} sneakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async likeSneakerById(sneakerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.likeSneakerById(sneakerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify a sneaker using an id
         * @summary modify one sneaker
         * @param {string} sneakerId 
         * @param {ISneaker} iSneaker The sneaker object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSneaker(sneakerId: string, iSneaker: ISneaker, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISneaker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSneaker(sneakerId, iSneaker, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SneakerApi - factory interface
 * @export
 */
export const SneakerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SneakerApiFp(configuration)
    return {
        /**
         * create any type of Sneaker
         * @summary create sneaker
         * @param {ISneaker} iSneaker The sneaker object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSneaker(iSneaker: ISneaker, options?: any): AxiosPromise<ISneaker> {
            return localVarFp.createSneaker(iSneaker, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a sneaker using an id
         * @summary delete sneaker
         * @param {string} sneakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSneaker(sneakerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSneaker(sneakerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get one sneaker using an id
         * @summary get one sneaker
         * @param {string} sneakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSneakerById(sneakerId: string, options?: any): AxiosPromise<ISneaker> {
            return localVarFp.getSneakerById(sneakerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get sneaker of the day
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSneakerOfTheDay(options?: any): AxiosPromise<ISneaker> {
            return localVarFp.getSneakerOfTheDay(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all sneaker sizes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSneakerSizes(options?: any): AxiosPromise<Array<ISizeDTO>> {
            return localVarFp.getSneakerSizes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all sneakers
         * @param {ISneakerFilter} iSneakerFilter The sneaker object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSneakers(iSneakerFilter: ISneakerFilter, options?: any): AxiosPromise<Array<ISneaker>> {
            return localVarFp.getSneakers(iSneakerFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all liked sneakers by user
         * @param {IProductFilter} iProductFilter The sneaker filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLikedSneakers(iProductFilter: IProductFilter, options?: any): AxiosPromise<ISneakerListPaged> {
            return localVarFp.getUserLikedSneakers(iProductFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * like/dislike one sneaker
         * @summary like/dislike one sneaker
         * @param {string} sneakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeSneakerById(sneakerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.likeSneakerById(sneakerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Modify a sneaker using an id
         * @summary modify one sneaker
         * @param {string} sneakerId 
         * @param {ISneaker} iSneaker The sneaker object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSneaker(sneakerId: string, iSneaker: ISneaker, options?: any): AxiosPromise<ISneaker> {
            return localVarFp.updateSneaker(sneakerId, iSneaker, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SneakerApi - object-oriented interface
 * @export
 * @class SneakerApi
 * @extends {BaseAPI}
 */
export class SneakerApi extends BaseAPI {
    /**
     * create any type of Sneaker
     * @summary create sneaker
     * @param {ISneaker} iSneaker The sneaker object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SneakerApi
     */
    public createSneaker(iSneaker: ISneaker, options?: AxiosRequestConfig) {
        return SneakerApiFp(this.configuration).createSneaker(iSneaker, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a sneaker using an id
     * @summary delete sneaker
     * @param {string} sneakerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SneakerApi
     */
    public deleteSneaker(sneakerId: string, options?: AxiosRequestConfig) {
        return SneakerApiFp(this.configuration).deleteSneaker(sneakerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get one sneaker using an id
     * @summary get one sneaker
     * @param {string} sneakerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SneakerApi
     */
    public getSneakerById(sneakerId: string, options?: AxiosRequestConfig) {
        return SneakerApiFp(this.configuration).getSneakerById(sneakerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get sneaker of the day
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SneakerApi
     */
    public getSneakerOfTheDay(options?: AxiosRequestConfig) {
        return SneakerApiFp(this.configuration).getSneakerOfTheDay(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all sneaker sizes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SneakerApi
     */
    public getSneakerSizes(options?: AxiosRequestConfig) {
        return SneakerApiFp(this.configuration).getSneakerSizes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all sneakers
     * @param {ISneakerFilter} iSneakerFilter The sneaker object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SneakerApi
     */
    public getSneakers(iSneakerFilter: ISneakerFilter, options?: AxiosRequestConfig) {
        return SneakerApiFp(this.configuration).getSneakers(iSneakerFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all liked sneakers by user
     * @param {IProductFilter} iProductFilter The sneaker filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SneakerApi
     */
    public getUserLikedSneakers(iProductFilter: IProductFilter, options?: AxiosRequestConfig) {
        return SneakerApiFp(this.configuration).getUserLikedSneakers(iProductFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * like/dislike one sneaker
     * @summary like/dislike one sneaker
     * @param {string} sneakerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SneakerApi
     */
    public likeSneakerById(sneakerId: string, options?: AxiosRequestConfig) {
        return SneakerApiFp(this.configuration).likeSneakerById(sneakerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify a sneaker using an id
     * @summary modify one sneaker
     * @param {string} sneakerId 
     * @param {ISneaker} iSneaker The sneaker object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SneakerApi
     */
    public updateSneaker(sneakerId: string, iSneaker: ISneaker, options?: AxiosRequestConfig) {
        return SneakerApiFp(this.configuration).updateSneaker(sneakerId, iSneaker, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoreApi - axios parameter creator
 * @export
 */
export const StoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get one store using an id
         * @summary add comment to store
         * @param {string} storeId 
         * @param {IStoreComment} iStoreComment The store comment object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentToStore: async (storeId: string, iStoreComment: IStoreComment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('addCommentToStore', 'storeId', storeId)
            // verify required parameter 'iStoreComment' is not null or undefined
            assertParamExists('addCommentToStore', 'iStoreComment', iStoreComment)
            const localVarPath = `/store/{storeId}/comment`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iStoreComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a comment to store by order
         * @summary add comment to store by order
         * @param {IStoreComment} iStoreComment The store comment object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentToStoreByOrder: async (iStoreComment: IStoreComment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iStoreComment' is not null or undefined
            assertParamExists('addCommentToStoreByOrder', 'iStoreComment', iStoreComment)
            const localVarPath = `/store/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iStoreComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create one store
         * @summary create store
         * @param {any} [logo] file
         * @param {any} [banner] file
         * @param {IStore} [store] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStore: async (logo?: any, banner?: any, store?: IStore, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/store`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
            if (banner !== undefined) { 
                localVarFormParams.append('banner', banner as any);
            }
    
            if (store !== undefined) { 
                localVarFormParams.append('store', new Blob([JSON.stringify(store)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a store using an id
         * @summary delete sneaker
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStore: async (storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('deleteStore', 'storeId', storeId)
            const localVarPath = `/store/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a store comment using an id
         * @summary delete store comment
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreComment: async (commentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteStoreComment', 'commentId', commentId)
            const localVarPath = `/store/comment/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get one store using current User
         * @summary get one store by current User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreByCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/store/current-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get one store using an id
         * @summary get one store by id
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreById: async (storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getStoreById', 'storeId', storeId)
            const localVarPath = `/store/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get one store using an name
         * @summary get one store by name
         * @param {string} storeName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreByName: async (storeName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeName' is not null or undefined
            assertParamExists('getStoreByName', 'storeName', storeName)
            const localVarPath = `/store/name/{storeName}`
                .replace(`{${"storeName"}}`, encodeURIComponent(String(storeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get store comments
         * @summary get store comments
         * @param {string} storeId 
         * @param {IStoreCommentFilter} iStoreCommentFilter The store comment filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreCommentsByStoreId: async (storeId: string, iStoreCommentFilter: IStoreCommentFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getStoreCommentsByStoreId', 'storeId', storeId)
            // verify required parameter 'iStoreCommentFilter' is not null or undefined
            assertParamExists('getStoreCommentsByStoreId', 'iStoreCommentFilter', iStoreCommentFilter)
            const localVarPath = `/store/{storeId}/comment/list`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iStoreCommentFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all stores
         * @param {IStoreFilter} iStoreFilter The user filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreList: async (iStoreFilter: IStoreFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iStoreFilter' is not null or undefined
            assertParamExists('getStoreList', 'iStoreFilter', iStoreFilter)
            const localVarPath = `/store/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iStoreFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify a store using an id
         * @summary modify one store
         * @param {string} storeId 
         * @param {any} [logo] file
         * @param {any} [banner] file
         * @param {IStore} [store] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStore: async (storeId: string, logo?: any, banner?: any, store?: IStore, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('updateStore', 'storeId', storeId)
            const localVarPath = `/store/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
            if (banner !== undefined) { 
                localVarFormParams.append('banner', banner as any);
            }
    
            if (store !== undefined) { 
                localVarFormParams.append('store', new Blob([JSON.stringify(store)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update store comment using an id
         * @summary update store comment by id
         * @param {string} commentId 
         * @param {IStoreComment} iStoreComment The store comment object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreCommentById: async (commentId: string, iStoreComment: IStoreComment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('updateStoreCommentById', 'commentId', commentId)
            // verify required parameter 'iStoreComment' is not null or undefined
            assertParamExists('updateStoreCommentById', 'iStoreComment', iStoreComment)
            const localVarPath = `/store/comment/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iStoreComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreApi - functional programming interface
 * @export
 */
export const StoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreApiAxiosParamCreator(configuration)
    return {
        /**
         * Get one store using an id
         * @summary add comment to store
         * @param {string} storeId 
         * @param {IStoreComment} iStoreComment The store comment object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCommentToStore(storeId: string, iStoreComment: IStoreComment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStoreComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCommentToStore(storeId, iStoreComment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a comment to store by order
         * @summary add comment to store by order
         * @param {IStoreComment} iStoreComment The store comment object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCommentToStoreByOrder(iStoreComment: IStoreComment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStoreComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCommentToStoreByOrder(iStoreComment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create one store
         * @summary create store
         * @param {any} [logo] file
         * @param {any} [banner] file
         * @param {IStore} [store] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStore(logo?: any, banner?: any, store?: IStore, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStore(logo, banner, store, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a store using an id
         * @summary delete sneaker
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStore(storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStore(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a store comment using an id
         * @summary delete store comment
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStoreComment(commentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStoreComment(commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get one store using current User
         * @summary get one store by current User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreByCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreByCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get one store using an id
         * @summary get one store by id
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreById(storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreById(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get one store using an name
         * @summary get one store by name
         * @param {string} storeName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreByName(storeName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreByName(storeName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get store comments
         * @summary get store comments
         * @param {string} storeId 
         * @param {IStoreCommentFilter} iStoreCommentFilter The store comment filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreCommentsByStoreId(storeId: string, iStoreCommentFilter: IStoreCommentFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStoreCommentListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreCommentsByStoreId(storeId, iStoreCommentFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all stores
         * @param {IStoreFilter} iStoreFilter The user filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreList(iStoreFilter: IStoreFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStoreListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreList(iStoreFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify a store using an id
         * @summary modify one store
         * @param {string} storeId 
         * @param {any} [logo] file
         * @param {any} [banner] file
         * @param {IStore} [store] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStore(storeId: string, logo?: any, banner?: any, store?: IStore, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStore(storeId, logo, banner, store, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update store comment using an id
         * @summary update store comment by id
         * @param {string} commentId 
         * @param {IStoreComment} iStoreComment The store comment object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStoreCommentById(commentId: string, iStoreComment: IStoreComment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStoreComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStoreCommentById(commentId, iStoreComment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreApi - factory interface
 * @export
 */
export const StoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreApiFp(configuration)
    return {
        /**
         * Get one store using an id
         * @summary add comment to store
         * @param {string} storeId 
         * @param {IStoreComment} iStoreComment The store comment object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentToStore(storeId: string, iStoreComment: IStoreComment, options?: any): AxiosPromise<IStoreComment> {
            return localVarFp.addCommentToStore(storeId, iStoreComment, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a comment to store by order
         * @summary add comment to store by order
         * @param {IStoreComment} iStoreComment The store comment object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentToStoreByOrder(iStoreComment: IStoreComment, options?: any): AxiosPromise<IStoreComment> {
            return localVarFp.addCommentToStoreByOrder(iStoreComment, options).then((request) => request(axios, basePath));
        },
        /**
         * create one store
         * @summary create store
         * @param {any} [logo] file
         * @param {any} [banner] file
         * @param {IStore} [store] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStore(logo?: any, banner?: any, store?: IStore, options?: any): AxiosPromise<IStore> {
            return localVarFp.createStore(logo, banner, store, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a store using an id
         * @summary delete sneaker
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStore(storeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteStore(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a store comment using an id
         * @summary delete store comment
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreComment(commentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteStoreComment(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get one store using current User
         * @summary get one store by current User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreByCurrentUser(options?: any): AxiosPromise<IStore> {
            return localVarFp.getStoreByCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Get one store using an id
         * @summary get one store by id
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreById(storeId: string, options?: any): AxiosPromise<IStore> {
            return localVarFp.getStoreById(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get one store using an name
         * @summary get one store by name
         * @param {string} storeName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreByName(storeName: string, options?: any): AxiosPromise<IStore> {
            return localVarFp.getStoreByName(storeName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get store comments
         * @summary get store comments
         * @param {string} storeId 
         * @param {IStoreCommentFilter} iStoreCommentFilter The store comment filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreCommentsByStoreId(storeId: string, iStoreCommentFilter: IStoreCommentFilter, options?: any): AxiosPromise<IStoreCommentListPaged> {
            return localVarFp.getStoreCommentsByStoreId(storeId, iStoreCommentFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all stores
         * @param {IStoreFilter} iStoreFilter The user filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreList(iStoreFilter: IStoreFilter, options?: any): AxiosPromise<IStoreListPaged> {
            return localVarFp.getStoreList(iStoreFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Modify a store using an id
         * @summary modify one store
         * @param {string} storeId 
         * @param {any} [logo] file
         * @param {any} [banner] file
         * @param {IStore} [store] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStore(storeId: string, logo?: any, banner?: any, store?: IStore, options?: any): AxiosPromise<IStore> {
            return localVarFp.updateStore(storeId, logo, banner, store, options).then((request) => request(axios, basePath));
        },
        /**
         * Update store comment using an id
         * @summary update store comment by id
         * @param {string} commentId 
         * @param {IStoreComment} iStoreComment The store comment object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreCommentById(commentId: string, iStoreComment: IStoreComment, options?: any): AxiosPromise<IStoreComment> {
            return localVarFp.updateStoreCommentById(commentId, iStoreComment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreApi - object-oriented interface
 * @export
 * @class StoreApi
 * @extends {BaseAPI}
 */
export class StoreApi extends BaseAPI {
    /**
     * Get one store using an id
     * @summary add comment to store
     * @param {string} storeId 
     * @param {IStoreComment} iStoreComment The store comment object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addCommentToStore(storeId: string, iStoreComment: IStoreComment, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addCommentToStore(storeId, iStoreComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a comment to store by order
     * @summary add comment to store by order
     * @param {IStoreComment} iStoreComment The store comment object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addCommentToStoreByOrder(iStoreComment: IStoreComment, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addCommentToStoreByOrder(iStoreComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create one store
     * @summary create store
     * @param {any} [logo] file
     * @param {any} [banner] file
     * @param {IStore} [store] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public createStore(logo?: any, banner?: any, store?: IStore, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).createStore(logo, banner, store, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a store using an id
     * @summary delete sneaker
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public deleteStore(storeId: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).deleteStore(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a store comment using an id
     * @summary delete store comment
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public deleteStoreComment(commentId: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).deleteStoreComment(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get one store using current User
     * @summary get one store by current User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreByCurrentUser(options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getStoreByCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get one store using an id
     * @summary get one store by id
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreById(storeId: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getStoreById(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get one store using an name
     * @summary get one store by name
     * @param {string} storeName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreByName(storeName: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getStoreByName(storeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get store comments
     * @summary get store comments
     * @param {string} storeId 
     * @param {IStoreCommentFilter} iStoreCommentFilter The store comment filter object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreCommentsByStoreId(storeId: string, iStoreCommentFilter: IStoreCommentFilter, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getStoreCommentsByStoreId(storeId, iStoreCommentFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all stores
     * @param {IStoreFilter} iStoreFilter The user filter object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreList(iStoreFilter: IStoreFilter, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getStoreList(iStoreFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify a store using an id
     * @summary modify one store
     * @param {string} storeId 
     * @param {any} [logo] file
     * @param {any} [banner] file
     * @param {IStore} [store] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public updateStore(storeId: string, logo?: any, banner?: any, store?: IStore, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).updateStore(storeId, logo, banner, store, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update store comment using an id
     * @summary update store comment by id
     * @param {string} commentId 
     * @param {IStoreComment} iStoreComment The store comment object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public updateStoreCommentById(commentId: string, iStoreComment: IStoreComment, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).updateStoreCommentById(commentId, iStoreComment, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get stripe current Subscription
         * @summary get current Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSubscription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscription/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Stripe Billing Session
         * @summary Get Stripe Billing Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripeBillingSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscription/billing-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get stripe SubscriptionList
         * @summary get stripe Subscription List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripeSubscriptionList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscription/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * Get stripe current Subscription
         * @summary get current Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentSubscription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISubscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentSubscription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Stripe Billing Session
         * @summary Get Stripe Billing Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStripeBillingSession(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStripeBillingSession(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get stripe SubscriptionList
         * @summary get stripe Subscription List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStripeSubscriptionList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IStripeSubscription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStripeSubscriptionList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionApiFp(configuration)
    return {
        /**
         * Get stripe current Subscription
         * @summary get current Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSubscription(options?: any): AxiosPromise<ISubscription> {
            return localVarFp.getCurrentSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Stripe Billing Session
         * @summary Get Stripe Billing Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripeBillingSession(options?: any): AxiosPromise<string> {
            return localVarFp.getStripeBillingSession(options).then((request) => request(axios, basePath));
        },
        /**
         * Get stripe SubscriptionList
         * @summary get stripe Subscription List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripeSubscriptionList(options?: any): AxiosPromise<Array<IStripeSubscription>> {
            return localVarFp.getStripeSubscriptionList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
    /**
     * Get stripe current Subscription
     * @summary get current Subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getCurrentSubscription(options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).getCurrentSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Stripe Billing Session
     * @summary Get Stripe Billing Session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getStripeBillingSession(options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).getStripeBillingSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get stripe SubscriptionList
     * @summary get stripe Subscription List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getStripeSubscriptionList(options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).getStripeSubscriptionList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates one type of address of the user.
         * @summary Creates an address for the user.
         * @param {IAddress} iAddress The address object to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAddress: async (iAddress: IAddress, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iAddress' is not null or undefined
            assertParamExists('createUserAddress', 'iAddress', iAddress)
            const localVarPath = `/user/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iAddress, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete address.
         * @summary delete address.
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddress: async (addressId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('deleteAddress', 'addressId', addressId)
            const localVarPath = `/user/address/{addressId}`
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get address list of the user.
         * @summary Get address list for the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user preference
         * @summary get user preference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreference: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/preference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get one user
         * @summary get one user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Weavy token
         * @summary Get weavy token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeavyToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/weavy-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set favorite address.
         * @summary Set favorite address.
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFavoriteAddress: async (addressId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('setFavoriteAddress', 'addressId', addressId)
            const localVarPath = `/user/address/favorite/{addressId}`
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update user password
         * @param {IUpdatePasswordRequest} iUpdatePasswordRequest UpdatePassword request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (iUpdatePasswordRequest: IUpdatePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iUpdatePasswordRequest' is not null or undefined
            assertParamExists('updatePassword', 'iUpdatePasswordRequest', iUpdatePasswordRequest)
            const localVarPath = `/user/update-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iUpdatePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify user preference
         * @summary modify user preference
         * @param {IUserPreference} iUserPreference The user\&#39;s preference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPreference: async (iUserPreference: IUserPreference, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iUserPreference' is not null or undefined
            assertParamExists('updateUserPreference', 'iUserPreference', iUserPreference)
            const localVarPath = `/user/preference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iUserPreference, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify a user
         * @summary modify one user
         * @param {any} [profilePhoto] file
         * @param {IUser} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile: async (profilePhoto?: any, user?: IUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (profilePhoto !== undefined) { 
                localVarFormParams.append('profilePhoto', profilePhoto as any);
            }
    
            if (user !== undefined) { 
                localVarFormParams.append('user', new Blob([JSON.stringify(user)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates one type of address of the user.
         * @summary Creates an address for the user.
         * @param {IAddress} iAddress The address object to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserAddress(iAddress: IAddress, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserAddress(iAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete address.
         * @summary delete address.
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAddress(addressId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAddress(addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get address list of the user.
         * @summary Get address list for the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IAddress>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get user preference
         * @summary get user preference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPreference(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserPreference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPreference(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get one user
         * @summary get one user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Weavy token
         * @summary Get weavy token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeavyToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeavyToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set favorite address.
         * @summary Set favorite address.
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setFavoriteAddress(addressId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setFavoriteAddress(addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update user password
         * @param {IUpdatePasswordRequest} iUpdatePasswordRequest UpdatePassword request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(iUpdatePasswordRequest: IUpdatePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IJwtAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(iUpdatePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify user preference
         * @summary modify user preference
         * @param {IUserPreference} iUserPreference The user\&#39;s preference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPreference(iUserPreference: IUserPreference, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserPreference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPreference(iUserPreference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify a user
         * @summary modify one user
         * @param {any} [profilePhoto] file
         * @param {IUser} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfile(profilePhoto?: any, user?: IUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfile(profilePhoto, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Creates one type of address of the user.
         * @summary Creates an address for the user.
         * @param {IAddress} iAddress The address object to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAddress(iAddress: IAddress, options?: any): AxiosPromise<void> {
            return localVarFp.createUserAddress(iAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * delete address.
         * @summary delete address.
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddress(addressId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAddress(addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get address list of the user.
         * @summary Get address list for the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressList(options?: any): AxiosPromise<Array<IAddress>> {
            return localVarFp.getAddressList(options).then((request) => request(axios, basePath));
        },
        /**
         * Get user preference
         * @summary get user preference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreference(options?: any): AxiosPromise<IUserPreference> {
            return localVarFp.getUserPreference(options).then((request) => request(axios, basePath));
        },
        /**
         * Get one user
         * @summary get one user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(options?: any): AxiosPromise<IUser> {
            return localVarFp.getUserProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Weavy token
         * @summary Get weavy token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeavyToken(options?: any): AxiosPromise<string> {
            return localVarFp.getWeavyToken(options).then((request) => request(axios, basePath));
        },
        /**
         * Set favorite address.
         * @summary Set favorite address.
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFavoriteAddress(addressId: string, options?: any): AxiosPromise<void> {
            return localVarFp.setFavoriteAddress(addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update user password
         * @param {IUpdatePasswordRequest} iUpdatePasswordRequest UpdatePassword request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(iUpdatePasswordRequest: IUpdatePasswordRequest, options?: any): AxiosPromise<IJwtAuthenticationResponse> {
            return localVarFp.updatePassword(iUpdatePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Modify user preference
         * @summary modify user preference
         * @param {IUserPreference} iUserPreference The user\&#39;s preference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPreference(iUserPreference: IUserPreference, options?: any): AxiosPromise<IUserPreference> {
            return localVarFp.updateUserPreference(iUserPreference, options).then((request) => request(axios, basePath));
        },
        /**
         * Modify a user
         * @summary modify one user
         * @param {any} [profilePhoto] file
         * @param {IUser} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile(profilePhoto?: any, user?: IUser, options?: any): AxiosPromise<IUser> {
            return localVarFp.updateUserProfile(profilePhoto, user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Creates one type of address of the user.
     * @summary Creates an address for the user.
     * @param {IAddress} iAddress The address object to be created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUserAddress(iAddress: IAddress, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createUserAddress(iAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete address.
     * @summary delete address.
     * @param {string} addressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteAddress(addressId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteAddress(addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get address list of the user.
     * @summary Get address list for the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAddressList(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getAddressList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user preference
     * @summary get user preference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserPreference(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserPreference(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get one user
     * @summary get one user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserProfile(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Weavy token
     * @summary Get weavy token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getWeavyToken(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getWeavyToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set favorite address.
     * @summary Set favorite address.
     * @param {string} addressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public setFavoriteAddress(addressId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).setFavoriteAddress(addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update user password
     * @param {IUpdatePasswordRequest} iUpdatePasswordRequest UpdatePassword request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updatePassword(iUpdatePasswordRequest: IUpdatePasswordRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updatePassword(iUpdatePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify user preference
     * @summary modify user preference
     * @param {IUserPreference} iUserPreference The user\&#39;s preference.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserPreference(iUserPreference: IUserPreference, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserPreference(iUserPreference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify a user
     * @summary modify one user
     * @param {any} [profilePhoto] file
     * @param {IUser} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserProfile(profilePhoto?: any, user?: IUser, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserProfile(profilePhoto, user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserManagementApi - axios parameter creator
 * @export
 */
export const UserManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * change user status
         * @summary change user status
         * @param {string} userId 
         * @param {IUserStatusEnum} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserStatus: async (userId: string, status?: IUserStatusEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('changeUserStatus', 'userId', userId)
            const localVarPath = `/user-management/{userId}/change-status`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create any type of user
         * @summary create user
         * @param {IUser} iUser The user object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (iUser: IUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iUser' is not null or undefined
            assertParamExists('createUser', 'iUser', iUser)
            const localVarPath = `/user-management`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a user using an id
         * @summary delete user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/user-management/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get one user using an id
         * @summary get one user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserById', 'userId', userId)
            const localVarPath = `/user-management/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all users
         * @param {IUserFilter} iUserFilter The user filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (iUserFilter: IUserFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iUserFilter' is not null or undefined
            assertParamExists('getUsers', 'iUserFilter', iUserFilter)
            const localVarPath = `/user-management/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iUserFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify a user using an id
         * @summary modify one user
         * @param {string} userId 
         * @param {IUser} iUser The user object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, iUser: IUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'iUser' is not null or undefined
            assertParamExists('updateUser', 'iUser', iUser)
            const localVarPath = `/user-management/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserManagementApi - functional programming interface
 * @export
 */
export const UserManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * change user status
         * @summary change user status
         * @param {string} userId 
         * @param {IUserStatusEnum} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserStatus(userId: string, status?: IUserStatusEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserStatus(userId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create any type of user
         * @summary create user
         * @param {IUser} iUser The user object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(iUser: IUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(iUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a user using an id
         * @summary delete user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get one user using an id
         * @summary get one user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all users
         * @param {IUserFilter} iUserFilter The user filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(iUserFilter: IUserFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(iUserFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify a user using an id
         * @summary modify one user
         * @param {string} userId 
         * @param {IUser} iUser The user object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, iUser: IUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, iUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserManagementApi - factory interface
 * @export
 */
export const UserManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserManagementApiFp(configuration)
    return {
        /**
         * change user status
         * @summary change user status
         * @param {string} userId 
         * @param {IUserStatusEnum} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserStatus(userId: string, status?: IUserStatusEnum, options?: any): AxiosPromise<void> {
            return localVarFp.changeUserStatus(userId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * create any type of user
         * @summary create user
         * @param {IUser} iUser The user object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(iUser: IUser, options?: any): AxiosPromise<IUser> {
            return localVarFp.createUser(iUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a user using an id
         * @summary delete user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get one user using an id
         * @summary get one user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(userId: string, options?: any): AxiosPromise<IUser> {
            return localVarFp.getUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all users
         * @param {IUserFilter} iUserFilter The user filter object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(iUserFilter: IUserFilter, options?: any): AxiosPromise<IUserListPaged> {
            return localVarFp.getUsers(iUserFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Modify a user using an id
         * @summary modify one user
         * @param {string} userId 
         * @param {IUser} iUser The user object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, iUser: IUser, options?: any): AxiosPromise<IUser> {
            return localVarFp.updateUser(userId, iUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserManagementApi - object-oriented interface
 * @export
 * @class UserManagementApi
 * @extends {BaseAPI}
 */
export class UserManagementApi extends BaseAPI {
    /**
     * change user status
     * @summary change user status
     * @param {string} userId 
     * @param {IUserStatusEnum} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public changeUserStatus(userId: string, status?: IUserStatusEnum, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).changeUserStatus(userId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create any type of user
     * @summary create user
     * @param {IUser} iUser The user object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public createUser(iUser: IUser, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).createUser(iUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a user using an id
     * @summary delete user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public deleteUser(userId: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get one user using an id
     * @summary get one user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getUserById(userId: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getUserById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all users
     * @param {IUserFilter} iUserFilter The user filter object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getUsers(iUserFilter: IUserFilter, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getUsers(iUserFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify a user using an id
     * @summary modify one user
     * @param {string} userId 
     * @param {IUser} iUser The user object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public updateUser(userId: string, iUser: IUser, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).updateUser(userId, iUser, options).then((request) => request(this.axios, this.basePath));
    }
}


